@import "../App.scss";
@import "variables";

.modal {
  & > .modal-dialog.edit-dataset-files-component {
    & > .modal-content {
      width: 800px;
      z-index: 1;

      & > .modal-header {
        margin-left: 32px;
        padding-left: 0px;

        & > .modal-title {
          font-family: SpaceGrotesk;
          font-size: 24px;
          font-weight: 400;
          line-height: 32px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: rgba(26, 28, 30, 1);
        }
      }

      & > .modal-body {
        margin-left: 32px;
        padding-left: 0px;

        & > .dataset-name,
        & > .dataset-version {
          height: 56px;
          padding: 16px;

          & > .heading {
            height: 16px;
            font-family: Space Mono;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: rgba(66, 71, 78, 1);
          }

          & > .detail {
            height: 24px;
            font-family: Space Mono;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: rgba(26, 28, 30, 1);
          }
        }

        & > .primary,
        & > .supporting-files,
        & > .additional-files {
          margin-top: 32px;
          font-family: SpaceGrotesk;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: rgba(81, 96, 111, 1);
        }

        & > .upload-file {
          margin-top: 12px;
          border: 0;
          border-radius: 30px;
          background: rgba(130, 166, 217, 1);
          font-family: SpaceGrotesk;
          font-weight: 500;
          padding: 8px 15px;
          color: #fff;
          text-align: center;
          cursor: pointer;
        }
        & > .upload-file:hover {
          background-color: rgba(130, 166, 217, 0.8);
        }

        & > .file-type {
          width: 204px;
          height: 56px;
          margin-left: 16px;
          margin-bottom: 32px;
          border-bottom: 1px solid rgba(194, 199, 207, 1);

          & > .supporting-file-type {
            height: 16px;
            margin-top: 20px;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.5px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }

          & > .dropdown {
            display: flex;
            justify-content: space-between;
          }
        }
      }

      & > .modal-footer {
        & > .footer {
          display: grid;
          grid-template-columns: 10% 75% 15%;
          align-items: center;
          width: 100%;
          margin: 36px 10px 36px 20px;

          & > .hg-button {
            & > .btn {
              padding: 10px 24px 10px 24px;
              display: flex;
              height: 32px;
              padding: 6px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              color: $big-blue-sys-light-on-surface-variant;
              background-color: transparent;
              text-align: center;
              font-size: 14px;
              font-family: SpaceGrotesk;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              text-transform: capitalize;
              border: none;

              &.btn-secondary {
                background: transparent;
                width: 60px;
                color: $big-blue-sys-light-primary;
              }

              &.btn-primary {
                border-radius: 100px;
                background: $big-blue-sys-light-primary;
                color: #fff;
                padding: 20px 24px 20px 24px;
              }
            }
          }
        }
      }
    }
  }
}
