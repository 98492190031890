.CSV-data-wideversion {
  align-items: center;
  background-color: var(--bigbluerefneutralneutral-96);
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  position: relative;
  background-color: #f3f3f6;
  width: 100%;
  height: 100%;
  // margin-left:354px;
  border-radius: 20px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  // margin-top:30px;

  .frame {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 720px;
  }

  .ADD-DATASETS {
    color: var(--bigbluesyslighton-primary-container);
    letter-spacing: var(--bigblue-headline-small-letter-spacing);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    height: 32px;
    font-family: "SpaceGrotesk";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  .close {
    position: relative;
  }

  .group {
    height: 50px;
    position: relative;
    width: 722px;
  }

  .step-basic {
    left: 3px;
    letter-spacing: 0;
    position: absolute;
    top: 0;
    white-space: nowrap;
    color: #00639a;
    font-family: "Space Mono";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .text-wrapper {
    color: #00639a;
    font-family: "SpaceGrotesk";
    font-size: var(--bigblue-body-large-font-size);
    font-style: var(--bigblue-body-large-font-style);
    font-weight: var(--bigblue-body-large-font-weight);
    letter-spacing: var(--bigblue-body-large-letter-spacing);
    line-height: var(--bigblue-body-large-line-height);
  }

  .span {
    font-family: "SpaceGrotesk";
    font-size: var(--bigblue-title-medium-font-size);
    font-style: var(--bigblue-title-medium-font-style);
    font-weight: var(--bigblue-title-medium-font-weight);
    letter-spacing: var(--bigblue-title-medium-letter-spacing);
    line-height: var(--bigblue-title-medium-line-height);
    margin-left: 5px;
  }

  .div {
    background-color: #d9d9d9;
    border-radius: 4px;
    height: 10px;
    left: 0;
    position: absolute;
    top: 40px;
    width: 720px;
  }

  .text-field-wrapper {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 720px;
    margin-top: 32px;
  }

  .text-field-wrapper1 {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 720px;
    margin-top: 16px;
    margin-left: 7px;
  }
  .text-field-wrapper99 {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 720px;
    margin-top: 16px;
    margin-left: 7px;
  }

  .text-field-wrapper100 {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 720px;
    margin-top: 16px;
    margin-left: 12px;
  }
  .text-field-wrapper2 {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 720px;
    margin-top: 48px;
  }

  .text-field {
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex-direction: column;
    height: 56px;
    position: relative;
    width: 100%;
  }

  .state-layer-wrapper {
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 100%;
  }

  .state-layer-2 {
    align-items: center;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex: 0 0 auto;
    padding: 4px 0px 4px 16px;
    position: relative;
    width: 100%;
    margin-top: 10px;
    // margin-left: -15px;
  }

    .state-layer-222 {
      align-items: center;
      align-self: stretch;
      border-radius: 4px 4px 0px 0px;
      display: flex;
      flex: 0 0 auto;
      padding: 4px 0px 4px 16px;
      position: relative;
      width: 100%;
      margin-top: 10px;
      margin-left: -15px;
    }
    .state-layer-214 {
      align-items: center;
      align-self: stretch;
      border-radius: 4px 4px 0px 0px;
      display: flex;
      flex: 0 0 auto;
      padding: 4px 0px 4px 16px;
      position: relative;
      width: 100%;
    }
    .state-layer-23 {
        align-items: center;
        align-self: stretch;
        border-radius: 4px 4px 0px 0px;
        display: flex;
        flex: 0 0 auto;
        padding: 4px 0px 4px 16px;
        position: relative;
        width: 100%;
        margin-top: 16px;
      }
  
    .content {
      align-items: flex-start;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-grow: 1;
      height: 40px;
      justify-content: center;
      position: relative;
      right: 5px;
    }
  
    .div-wrapper {
      align-items: center;
      display: inline-flex;
      flex: 0 0 auto;
      position: relative;
      width:100%
    }
    .div-wrapper1 {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        position: relative;
        margin-top: 32px;
      }
      .div-wrapper187 {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        position: relative;
        margin-top: 32px;
        margin-left: 8px;
      }
      .div-wrapper10000 {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        position: relative;
        margin-top: 32px;
        margin-left: 9px;
      }
      .div-wrapper199 {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        position: relative;
        margin-top: 32px;
        margin-left: 16px;
      }
      .div-wrapper100 {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        position: relative;
        margin-top: 16px;
        margin-left: -7px;
      }
      .div-wrapper111 {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        position: relative;
        margin-top: 32px;
        margin-left: 8px;
        flex-direction: column;
        height: '140px';
      }

  .div-wrapper2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
    margin-top: 32px;
    margin-left: 6px;
  }

  .label-text-2 {
    color: var(--bigbluesyslighton-surface-variant);
    letter-spacing: var(--bigblue-body-small-letter-spacing);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    color: #42474e;
    font-family: "Space Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  .label-text-200 {
    color: var(--bigbluesyslighton-surface-variant);
    letter-spacing: var(--bigblue-body-small-letter-spacing);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    color: #42474e;
    font-family: "SpaceGrotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-left: -14px;
  }

  .label-text-20 {
    letter-spacing: var(--bigblue-body-small-letter-spacing);
    position: relative;
    white-space: nowrap;
    width: fit-content;
    color: #51606f;
    font-family: "SpaceGrotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .label-text-201 {
    letter-spacing: var(--bigblue-body-small-letter-spacing);
    position: relative;
    white-space: nowrap;
    width: fit-content;
    color: #51606f;
    font-family: "SpaceGrotesk";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-left: 7px;
  }

  .label-text-202 {
    letter-spacing: var(--bigblue-body-small-letter-spacing);
    position: relative;
    white-space: nowrap;
    width: fit-content;
    color: #51606f;
    font-family: "SpaceGrotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: 720px;
  }
  .label-text-30 {
    letter-spacing: var(--bigblue-body-small-letter-spacing);
    position: relative;
    white-space: nowrap;
    width: fit-content;
    color: #51606f;
    font-family: "Space Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .input-text {
    align-items: center;
    background: none;
    border: none;
    color: var(--bigbluesyslighton-surface);
    display: inline-flex;
    flex: 0 0 auto;
    letter-spacing: var(--bigblue-body-large-letter-spacing);
    margin-top: -1px;
    padding: 0;
    position: relative;
    white-space: nowrap;
    color: #1a1c1e;
    font-family: "Space Mono";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .trailing-icon {
    height: 48px;
    position: relative;
    width: 48px;
  }

  .active-indicator {
    align-self: stretch;
    height: 1px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }

  .input-text-2 {
    color: var(--bigbluesyslighton-surface);
    font-family: var(--bigblue-body-large-font-family);
    font-size: var(--bigblue-body-large-font-size);
    font-style: var(--bigblue-body-large-font-style);
    font-weight: var(--bigblue-body-large-font-weight);
    letter-spacing: var(--bigblue-body-large-letter-spacing);
    line-height: var(--bigblue-body-large-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .dropdown-options {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    width: 100px;
    margin-top: 31%;
    z-index: 10;
  }
  .dropdown-options1 {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    width: 150px;
    margin-top: 23%;
    z-index: 10;
  }

  .dropdown-options2 {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    width: 375px;
    margin-top: 69%;
    z-index: 10;
  }

  .dropdown-option {
    padding: 10px;
    cursor: pointer;
  }

  .dropdown-option:hover {
    background-color: #f0f0f0;
  }
  .input-text-290 {
    color: "#1A1C1E";
    font-family: "Space Mono";
    font-size: 16px;
    font-style: var(--bigblue-body-large-font-style);
    font-weight: 400;
    letter-spacing: var(--bigblue-body-large-letter-spacing);
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .icons-arrow-drop {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }

  .frame-2 {
    align-items: center;
    background-color: #82a6d9;
    border-radius: 8px;
    display: flex;
    gap: 16px;
    height: 60px;
    justify-content: space-around;
    overflow: hidden;
    padding: 8px 0px;
    position: relative;
    width: 720px;
    margin-top: 32px;
  }
  .frame-2:hover {
    background-color: #77a0da;
  }

  .frame-200 {
    align-items: center;
    background-color: #82a6d9;
    border-radius: 8px;
    display: flex;
    gap: 16px;
    height: 60px;
    justify-content: space-around;
    overflow: hidden;
    padding: 8px 0px;
    position: relative;
    width: 720px;
    margin-top: 32px;
    margin-left: 10px;
  }

  .text-wrapper-2 {
    color: var(--bigbluesyslighton-primary-container);
    font-family: "SpaceGrotesk";
    font-size: var(--bigblue-title-medium-font-size);
    font-style: var(--bigblue-title-medium-font-style);
    font-weight: 500;
    letter-spacing: var(--bigblue-title-medium-letter-spacing);
    line-height: var(--bigblue-title-medium-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .FILE-NAME-googlegeo-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    height: 20px;
    position: relative;
    width: 199px;
    margin-top: 32px;
    margin-left: 260px;
  }
  .FILE-NAME-googlegeo-wrapper1 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    height: 20px;
    position: relative;
    width: 199px;
    margin-top: 16px;
  }

  .FILE-NAME-googlegeo {
    color: transparent;
    font-family: var(--bigblue-title-small-font-family);
    font-size: var(--bigblue-title-small-font-size);
    font-style: var(--bigblue-title-small-font-style);
    font-weight: var(--bigblue-title-small-font-weight);
    letter-spacing: var(--bigblue-title-small-letter-spacing);
    line-height: var(--bigblue-title-small-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 238%;
  }

  .text-wrapper-3 {
    color: #51606f;
    letter-spacing: var(--bigblue-title-small-letter-spacing);
    font-family: "SpaceGrotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .text-wrapper-4 {
    color: #00639a;
    letter-spacing: var(--bigblue-title-small-letter-spacing);
    font-family: "SpaceGrotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-wrap: wrap;
  }

  .frame-3 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    height: 152px;
    justify-content: center;
    position: relative;
  }

  .rectangle {
    align-items: flex-start;
    background-color: var(--bigbluesyslightbackground);
    border-radius: 4px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 16px;
    position: relative;
    width: 720px;
  }

  .frame-4 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    position: relative;
  }

  .svg {
    height: 56px;
    position: relative;
    width: 56px;
  }

  .frame-5 {
    align-items: center;
    display: inline-flex;
    gap: 16px;
    height: 64px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .DIVIDER-DIVIDER {
    background-color: var(--bigbluesyslightoutline-variant);
    height: 1px;
    position: relative;
    width: 720px;
  }

  .text-wrapper-5 {
    color: #51606f;
    font-family: var(--bigblue-body-small-font-family);
    font-size: var(--bigblue-body-small-font-size);
    font-style: var(--bigblue-body-small-font-style);
    font-weight: var(--bigblue-body-small-font-weight);
    letter-spacing: var(--bigblue-body-small-letter-spacing);
    line-height: var(--bigblue-body-small-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .line-wrapper {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;
  }

  .LINE-DIVIDER {
    background-color: var(--bigbluesyslightoutline-variant);
    height: 1px;
    position: relative;
    width: 720px;
  }

  .rectangle-2 {
    background-color: var(--bigbluesyslightbackground);
    border-radius: 8px;
    height: 144px;
    position: relative;
    width: 720px;
  }

  .csv-data {
    align-items: flex-start;
    display: inline-flex;
    flex: 1;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    position: relative;
  }

  .text-wrapper-6 {
    color: #00639a;
    font-family: var(--bigblue-label-large-font-family);
    font-size: var(--bigblue-label-large-font-size);
    font-style: var(--bigblue-label-large-font-style);
    font-weight: var(--bigblue-label-large-font-weight);
    letter-spacing: var(--bigblue-label-large-letter-spacing);
    line-height: var(--bigblue-label-large-line-height);
  }

  .content-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
  }

  .text-wrapper-7 {
    color: var(--bigbluesyslightprimary);
    font-family: var(--bigblue-label-large-font-family);
    font-size: var(--bigblue-label-large-font-size);
    font-style: var(--bigblue-label-large-font-style);
    font-weight: var(--bigblue-label-large-font-weight);
    letter-spacing: var(--bigblue-label-large-letter-spacing);
    line-height: var(--bigblue-label-large-line-height);
  }

  .group-2 {
    align-items: center;
    display: flex;
    gap: 40px;
    height: 20px;
    position: relative;
    width: 100%;
  }

  .component-wrapper {
    align-items: flex-start;
    background-color: var(--bigbluesyslightbackground);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    padding: 0px 16px;
    position: relative;
    width: 720px;
  }

  .group-3 {
    align-items: center;
    display: flex;
    gap: 40px;
    height: 20px;
    position: relative;
    width: 100%;
  }

  .button-base {
    align-items: flex-start;
    align-self: flex-start;
    border-radius: 4px;
    display: inline-flex;
    height: 36px;
    justify-content: center;
    padding: 8px 24px;
    position: relative;
    width: 144px;
  }

  .text-wrapper-8 {
    color: var(--bigbluesyslightprimary);
    font-family: var(--bigblue-label-large-font-family);
    font-size: var(--bigblue-label-large-font-size);
    font-style: var(--bigblue-label-large-font-style);
    font-weight: var(--bigblue-label-large-font-weight);
    letter-spacing: var(--bigblue-label-large-letter-spacing);
    line-height: var(--bigblue-label-large-line-height);
  }

  .BACK-SKIP-NEXT {
    display: flex;
    width: 720px;
    height: 72px;
    justify-content: space-between;
    align-items: flex-end;
  }

  .checking {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px;
    position: relative;
  }
}
.shape-file-section {
  margin-top: 2em;
  .shp-button {
    background-color: #82a6d9;
    border-radius: 2rem;
    border: none;
    padding: 0.5em 1em;
    color: white;
    font-family: "SpaceGrotesk";
    font-size: var(--bigblue-title-medium-font-size);
    font-style: var(--bigblue-title-medium-font-style);
    font-weight: 500;
    letter-spacing: var(--bigblue-title-medium-letter-spacing);
    line-height: var(--bigblue-title-medium-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .shp-list {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    margin-bottom: 2em;
    gap: 1em;
    .shp-list-item {
      margin-left: 1.5em;
    }
  }
}

// License UI Styles
.license-type-card-container {
  margin-top: 32px;
  width: 100%;

  .dataset-license-title {
    color: var(--BigBlue-sys-light-on-surface-variant, #42474e);
    font-family: "SpaceGrotesk";
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .desc {
    color: var(--BigBlue-sys-light-on-surface, #1a1c1e);
    font-family: "SpaceGrotesk";
    font-size: 14px;
    margin-bottom: 16px;
    font-weight: 700;

    .lightgrey-text-section {
      color: #bbb;
    }
  }

  .license-cards {
    display: flex;
    gap: 16px;
    width: 100%;
    height: 192px;
    margin-bottom: 30px;

    .license-card {
      min-height: 120px;
      padding: 24px;
      flex: 1;
      border-radius: 8px;
      border: 1px solid var(--BigBlue-sys-light-outline, #71787e);
      background: var(--BigBlue-ref-neutral-neutral-99, #fcfcff);
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      &:hover {
        border-color: #00639A;
        background: #F5F9FC;
      }

      &.blue {
        border: 2px solid #00639A;
        background: #F5F9FC;
      }

      .card-title {
        color: var(--BigBlue-sys-light-on-surface, #1a1c1e);
        font-family: "SpaceGrotesk";
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 8px;
      }

      p {
        color: var(--BigBlue-sys-light-on-surface-variant, #42474e);
        font-family: "SpaceGrotesk";
        font-size: 14px;
        line-height: 20px;
        margin: 0;
      }
    }
  }

  
}

.version-toggle {
  display: flex;
  align-items: center;
  gap: 12px !important;
  margin-top: 16px !important;
  margin-bottom: 24px;
  padding-left: 4px;
  margin-left: 0;
  width: 313px;

  .toggle-label {
    font-family: 'Space Mono', monospace !important;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: #42474E;
  }

  .MuiSwitch-root {
    width: 50px;
    height: 26px;
    margin-right: 8px !important;
  }
}

.manage-license-help-heading {
  color: var(--BigBlue-sys-light-on-surface, #1a1c1e);
  font-family: "SpaceGrotesk";
  font-size: 14px;
  margin: 24px 0 8px;

  .lightgrey-text-section {
    color: #51606F;
  }
}

.manage-license-dataset-license-container {
  margin-top: 24px;

  .manage-license-heading {
    color: var(--BigBlue-sys-light-on-surface-variant, #42474e);
    font-family: "SpaceGrotesk";
    font-size: 14px;
    margin-bottom: -13px;
    margin-left: 15px;
  }
}

.manage-license-view-license-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  .manage-license-view-license-button {
    display: flex;
    align-self: flex-start;
    padding: 0.4em 2em;
    justify-content: center;
    align-items: center;
    color: var(--Big-Blue, #82a6d9);
    text-align: center;
    /* BigBlue/label/large */
    // font-family: "Space Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
    background-color: transparent;
    border-radius: 1.5em;
    border: 1px solid rgba(130, 166, 217, 1);
    width: Fixed (180px) px;
    height: Fixed (32px) px;
  }
}

.manage-license-upload-button {
  padding: 10px 24px;
  border-radius: 100px;
  background: var(--BigBlue-sys-light-primary, #00639a);
  color: white;
  border: none;
  font-family: "SpaceGrotesk";
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 16px;

  &:hover {
    background: #005483;
  }
}

.manage-license-file-name-section {
  color: var(--BigBlue-sys-light-on-surface-variant, #42474e);
  font-family: "SpaceGrotesk";
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 24px;

  .manage-license-file-name {
    color: #00639A;
    margin-left: 4px;
  }
}

 
  