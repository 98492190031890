.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  .add-icon {
    width: 50px;
    height: 50px;
  }
  input {
    display: none;
  }

  .img-container {
    border-radius: 50%;
    border: 3px solid black;
    height: 150px;
    width: 150px;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: relative;
    margin-bottom: 25px;
    img {
      display: block;
      border-radius: 50%;
      transition: transform 0.3s ease-in-out;
    }
    .img-container:hover img {
        transform: scale(1.1);
      }
    
  }
  .img-container:hover::before {
    content: "Upload Image";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
  }
  .img-container::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
}
footer {
  margin-top: 100px;
  a {
    color: black;
    text-decoration: none;
  }
}
