@import '../App.scss';
@import 'variables';



    .modal {

        & > .modal-dialog.share-dataset-component {
    
            & > .modal-content {
    
                & > .modal-header {
    
                    & > .modal-title {
                        color: $big-blue-sys-light-on-surface;
                        font-size: 24px;
                        font-family: SpaceGrotesk;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 32px;
                    }
                }
    
                & > .modal-body {
                    background: none;
    
                    & > .gen-info {
                        color: $big-blue-sys-light-on-surface-variant;
                        font-size: 14px;
                        font-family: 'Space Mono';
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        margin: 16px 0 24px;
                        width: 55%;
                    }
    
                    & > .dsg-form {
                        display: flex;
                        flex-wrap: wrap;
    
                        & > fieldset {
                            display: flex;
                            gap: 30px;
                            width: 100%;
    
                            &>.form-field {
                            
                                    &:first-child {
                                        flex: 1;
                                    }
                            
                                    &.drop-down {
                                        width: 140px;
                                    }
                            
                                    &>.error-message {
                                        font-family: Roboto;
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 20px;
                                        letter-spacing: 0.25px;
                                        color: rgba(255, 61, 0, 1);
                            
                                    }
                                & > .form-label {
                                    color: $m-3-sys-light-on-surface-variant;
                                    font-family: Roboto;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 16px; /* 133.333% */
                                    letter-spacing: 0.5px;
                                    text-transform: unset;
                                    margin-bottom: -10px;
                                }
        
                                & > .form-control {
                                    outline: none;
                                    border: none;
                                    border-bottom: 1px solid;
                                    border-radius: 0;
                                    padding: 0;
                                    height: 40px;
                                    color: $big-blue-sys-light-on-surface;
                                    font-family: "Space Mono";
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 24px; /* 150% */
                                    border-bottom: 1px solid #C2C7CF;
                                }
        
                                & > .dsg-dropdown {
        
                                    & > .selected-item {
                                        border: none;
                                        border-bottom: 1px solid #C2C7CF;
                                        border-radius: 0;
                                        height: 40px;
                                        padding-left: 0;
                                    }
        
                                    & > .dropdown-list {
                                        border-radius: 0;
                                        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
                                        border: none;
                                        padding: 0;
        
                                        & > ul {
                                            padding: 0;
        
                                            & > li {
                                                border: none;
                                                transition: all linear 100ms;
                                                padding: 11px 16px;
        
                                                &:hover {
                                                    background-color: #F3F3F6;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
    
                    & > .additional-users {
                        width: 62%;
                        display: flex;
                        justify-content: space-between;
                        
                        & > img {
                            cursor: pointer;
                        }
                    }
                }
    
                & > .modal-footer {
                    border: none;
                }
            
            }
        }
    }


