.modal {
  & > .search-modal {
    & > .modal-content {
      padding: 5px 30px;
      margin-left: 0;
      min-height: 630px;
      .license-header {
        display: flex;
        flex-direction: column;
        line-height: normal;
        margin-top: 25px;
      }
      & > .modal-footer {
        margin-top: 10px;
        justify-content: space-between;
        & > .hg-button {
          & > .btn.btn-secondary {
            color: rgba(0, 99, 154, 1);
          }
        }
        & > .btn-sumbit {
          & > .btn-back {
            background-color: transparent;
            border: 1px solid rgba(0, 99, 154, 1);
            color: rgba(0, 99, 154, 1);
          }
          & > button {
            background-color: rgba(0, 99, 154, 1);
            padding: 8px 25px;
            color: #fafafa;
            border-radius: 40px;
            border: none;
            margin-left: 10px;
          }
          & > button:hover {
            background-color: #347ba1;
          }
          & > .btn-back:hover {
            background-color: #f1faff;
          }
        }
      }
    }
  }
}

.search-modal-body {
  & > .search-input {
    position: relative;
    & > input {
      width: 100%;
      height: 55px;
      border: none;
      background-color: #fcfcff;
      border-radius: 33px;
      box-shadow: 5px 2px 10px #cbcbcb;
      margin-bottom: 10px;
      padding: 0px 30px;
    }
    & > .search-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-100%, -70%);
    }
  }
  & > .dataset-table {
    width: 100%;
    height: 330px;
    overflow-y: auto;
    scrollbar-width: none;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead {
    background-color: #fafafa;
    position: sticky;
    top: 0; /* Keep the header fixed at the top */
    z-index: 1; /* Ensures the header stays above the body */
  }
  td,
  th {
    max-width: 15vw;
    text-align: left;
    padding: 14px 0px;
    & > {
      input {
        margin: 0px 5px;
        width: 15px;
        height: 15px;
      }
    }
  }
  td:nth-child(5) {
    text-align: right;
    color: #00639a;
  }
  td:nth-child(5):hover {
    cursor: pointer;
  }

  th:nth-child(5) {
    text-align: right;
  }
  tbody > tr {
    border-bottom: 1px solid #72777f;
    background: none;
  }
}

.license-modal-body {
  & > .modal-body-heading {
    span {
      font-size: 12px;
    }
    p {
      font-size: 15px;
      margin: 0;
    }
  }
  & > .license-card-container {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    gap: 10px;

    & > .license-card {
      padding: 30px 10px;
      box-shadow: 0px 1px 3px 1px #00000026;
      box-shadow: 0px 1px 2px 0px #0000004d;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      cursor: pointer;
      box-sizing: border-box;
    }
  }
  & > .license-dropdown {
    & > .license-hgdropdown {
      & > .data-license {
        margin-bottom: -22px;
        padding-left: 15px;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.5px;
        text-align: left;
        color: rgba(73, 69, 79, 1);
      }
      & > .dsg-dropdown {
        & > .dropdown-list {
          ul {
            max-height: 120px;
          }
        }
      }
    }
    & > .btn-view-license {
      margin-top: 30px;
      background-color: transparent;
      border: 1px solid #82a6d9;
      color: #82a6d9;
      padding: 5px 20px;
      border-radius: 20px;
    }
  }
}

.dataset-description {
  font-size: 14px;
  font-weight: 400;
  color: #51606f;
}
