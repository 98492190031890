.category-view-component {
  .header {
    width: 100%;
    height: 40px;
    margin-top: 32px;
  }

  .main {
    width: 100%;
    padding-left: 128px;
    padding-right: 134px;
    & > .ocean-data {
      width: 288px;
      height: 24px;
      margin-top: 70px;
      font-family: spaceGrotesk;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: rgba(130, 130, 130, 1);
    }

    & > .active_filter {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
    }

    .category-view {
      display: grid;
      // justify-content: space-between;
      grid-template-columns: 1.6fr 1fr;
      height: 320px;
      margin-top: 40px;
      background: rgba(245, 245, 245, 1);

      .category-details {
        // width: 661px;
        padding: 73px 73px 73px 40px;
        // width: 548px;
        height: 320px;
        gap: 20px;
        border-radius: 8px 0px 0px 8px;

        .data-category {
          height: 24px;
          font-family: spaceGrotesk;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: rgba(130, 130, 130, 1);
        }

        .category-title {
          height: 40px;
          gap: 4px;
          font-family: spaceGrotesk;
          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
          text-align: left;
          color: rgba(0, 0, 0, 1);
        }

        .category-description {
          // width: 548px;
          height: 90px;
          margin-top: 20px;
          font-family: Space Mono;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.02em;
          text-align: left;
          color: rgba(128, 128, 128, 1);
          text-wrap: wrap;
        }
      }

      .category-img {
        // width: 467px;
        border-radius: 0px 8px 8px 0px;
      }
    }

    & > .datasets {
      display: flex;
      justify-content: space-between;
      height: 20px;
      margin-top: 60px;
      gap: 10px;

      & > .heading {
        width: 400px;
        height: 20px;
        font-family: SpaceGrotesk;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: rgba(69, 69, 69, 1);
      }

      & > .view-all {
        display: flex;
        width: 120px;
        height: 20px;
        left: 1008px;
        gap: 3px;

        .view-all-text {
          font-family: spaceGrotesk;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: right;
          color: rgba(194, 199, 207, 1);
        }

        .next-icon {
          width: 6.18px;
          height: 10px;
          top: 5px;
          left: 6.91px;
          margin-top: -4px;
          color: rgba(194, 199, 207, 1);
        }
      }
    }

    & > .popular-datasets-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      max-height: 450px;
      margin-top: 40px;
      column-gap: 24px;
      row-gap: 67px;
      overflow-y: hidden;
    }

    & > .popular-datasets-list {
      display: grid;
      grid-template-columns: 1fr;
      //display: flex;
      max-height: 300px;
      margin-top: 40px;
      overflow-y: hidden;
    }

    & > .all-datasets-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      min-height: 161px;
      max-height: 700px;
      margin-top: 40px;
      column-gap: 24px;
      row-gap: 67px;
      overflow-y: hidden;
    }

    & > .all-datasets-list {
      display: grid;
      grid-template-columns: 1fr;
      //display: flex;
      max-height: 600px;
      margin-top: 40px;
      overflow-y: hidden;
    }

    & > .all-datasets {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      max-height: 750px;
      margin-top: 40px;
      column-gap: 24px;
      row-gap: 67px;
      overflow-y: hidden;
    }

    & > .seperation-line {
      margin-top: 27px;
      margin-bottom: 38px;
      gap: 0px;
      border: 1px 0px 0px 0px;
      border: 1px solid rgba(205, 212, 230, 1);
    }
  }
}
