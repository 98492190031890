@import '../App.scss';

.lds-spinner {
    text-align: center;
    opacity: 0.8;

      &.sm {
        & > img {
            height: 50px;
            width: 50px;
        }
      }

      &.mg-top-10 {
        margin-top: 10px;
      }
}

@include media-breakpoint-down(md) {
  .lds-spinner {
    
    & > img {
      width: 80px;
    }
  }
}

@include media-breakpoint-up(md) {
    .lds-spinner {
    
    & > img {
      width: 100px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .lds-spinner {
    
    & > img {
      width: 120px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .lds-spinner {
    
    & > img {
      width: 130px;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .lds-spinner {
    
    & > img {
      width: 164px;
    }
  }
}