@import '../App.scss';

.dsg-search-component {
    padding-bottom: 20px;

    & > .main {
        & .image-helper-text {
            text-align: center;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #899DAB;

            & > img {
                margin: 73px 0 40px;
            }
        }

        & .search-container {
            max-width: 900px;
            margin: 0 auto;
        }

        & .seach-box {
            margin-top: 60px;

            & > .dsg-form {
                & > .form-field {
    
                    & > .form-control {
                        padding-left: 51px;
                    }
    
                    & > .form-icon.search::before {
                        content: "";
                        background: url('../images/search-glass.svg') no-repeat;
                        width: 14px;
                        height: 14px;
                        background-size: 14px;
                        position: absolute;
                        top: 23px;
                        left: 20px;
                    }

                    & > .form-icon.filter::before {
                        content: "";
                        background: url('../images/filters.svg') no-repeat;
                        width: 18px;
                        height: 18px;
                        background-size: 18px;
                        position: absolute;
                        top: 20px;
                        right: 20px;
                    }
                }
            }
        }

        & .top-three-models {
            & > .seaction-heading {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #45B9FF;
                margin: 40px 0 30px;
            }

            & > ul {
                display: flex;
                justify-content: space-between;

                & > li {
                    flex: 0 0 30%;
                    text-align: center;

                    & > .model-title {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 21px;
                        color: #FFFFFF;
                        margin-bottom: 10px;
                        cursor: pointer;
                        text-transform: capitalize;

                        &:hover {
                            & ~ .model-tags {
                                & > ul {
                                    & > li {
                                        background: #45B9FF;
                                    }
                                }
                            }
                        }
                    }

                    & > .model-description {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 17px;
                        color: #899DAB;
                        margin-bottom: 11px;
                    }

                    & > .model-tags {

                        & > ul {
                            text-align: center;

                            & > li {
                                background: #899DAB;
                                border-radius: 4px;
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 14px;
                                color: #001E30;
                                height: 21px;
                                padding: 0 6px;
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                margin: 5px 5px 0 0;
                                text-transform: lowercase;
                                transition: all linear 100ms;
                            }
                        }
                    }
                }
            }
        }

        & > .search-results-container {
            display: flex;
            flex-direction: row;
            padding-top: 23px;

            & > .filters {
                flex: 0 0 300px;
                padding-left: 41px;

                & > .section-heading {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 19px;
                    color: #45B9FF;
                    margin-bottom: 26px;
                }

                & > .filtes-list {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;
                    color: #FFFFFF;

                    & > li {
                        margin-bottom: 23px;
                        display: flex;
                        align-items: center;

                        & > .filter-name {
                            flex: 0 0 100px;
                            text-transform: capitalize;
                        }

                        & > .dsg-dropdown {
                            width: calc(100% - 100px);
                            flex: 1 0 calc(100% - 100px);
                        }

                        &.filter-tags {
                            flex-direction: column;
                            align-items: start;
                            margin-top: 35px;

                            & > .filter-name {
                                flex: 0 0 auto;
                                margin-bottom: 15px;
                            }

                            & > ul {

                                & > li {
                                    background: #899DAB;
                                    border-radius: 4px;
                                    font-weight: normal;
                                    font-size: 12px;
                                    line-height: 14px;
                                    color: #001E30;
                                    height: 21px;
                                    padding: 0 6px;
                                    display: inline-flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin: 0 5px 5px 0;
                                    text-transform: lowercase;
                                    cursor: pointer;

                                    &:hover {
                                       background: #45B9FF;
                                    }
                                }
                            }
                        }
                    }
                }

                & > .general-settings {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 34.32px;

                    & img {
                        cursor: pointer;
                    }

                    & > .dsg-form {
                        display: flex;

                        & > .form-field {
                            width: 57px;
                            margin: 0 5px;

                            & > .form-control.font-size {
                                width: 100%;
                                height: 30px;   
                                border-radius: 5.25px;
                                padding: 5px;
                                text-align: center;
                            }
                        }
                    }
                }

                & > .adv-settings,
                & > .adv-settings-1,
                & > .adv-settings-2,
                & > .adv-settings-3 {
                    margin-bottom: 26.32px;

                    & .section-heading {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14.0351px;
                        line-height: 17px;
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                    }
                }

                & > .adv-settings {
                    position: relative;
                    
                    & > .section-heading {
                        margin-bottom: 17px;
                    }

                    & > .creativity-selected {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 13px;
                        color: #FFFFFF;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }

                & > .adv-settings-1 {

                    & > .dsg-form {
                        & > .section-heading {
                            margin-bottom: 17.54px;
                        }

                        & > .form-field {
                            margin: 0;

                            & > .form-control {
                                height: 41px;
                                padding: 0 10.53px;
                                border-radius: 8px;
                            }
                        }
                    }
                }

                & > .adv-settings-2 {
                    
                    & > .sub-section {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }

                & > .adv-settings-3 {

                    & > .section-heading {
                        margin-bottom: 17px;
                    }

                    & > .dsg-dropdown {
                        & > .selected-item {
                            height: 41px;
                        }
                    }
                }
            }

            & > .recently-used-models {
                flex: 0 0 300px;
                padding-right: 71px;

                & > .top-three-models {

                    & > .seaction-heading {
                        text-align: left;
                        margin-top: 0;
                    }

                    & > ul {
                        flex-direction: column;

                        & > li {
                            text-align: left;
                            margin-bottom: 30px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            & > .model-tags {

                                & > ul {
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }

            & > .results {
                flex: 1 1 auto;
                padding: 0 47px;

                & > .go-back {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 19px;
                    color: #FFFFFF;
                    cursor: pointer;

                    & > img {
                        margin-right: 10px;
                    }
                }
                 
                & > .seach-box {
                    margin-top: 30px;
                }

                & > .total-results {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    color: #577692;
                    margin-top: 35px;
                }

                & > .model-results {
                    margin-top: 18px;
                    max-height: calc(100vh - 306px);
                    overflow-y: auto;


                    & > .top-three-models {

                        & > ul {
                            display: block;
                            justify-content: none;
                            
                            & > li {
                                text-align: left;
                                margin-bottom: 30px;
                                display: flex;
                                flex-wrap: wrap;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                & > .model-title,
                                & > .model-tags {
                                    display: inline-flex;

                                    & > ul {
                                        text-align: left;

                                        & > li {
                                            margin: 0 5px 5px 0;
                                        }
                                    }
                                }

                                & > .model-title {
                                    margin-right: 20px;
                                    order: 0;
                                    font-size: 22px;

                                    &:hover {
                                        & ~ .model-tags {
                                            & > ul {
                                                & > li {
                                                    background: #45B9FF;
                                                }
                                            }
                                        }
                                    }
                                }

                                & > .model-description {
                                    order: 1;
                                    flex: 1 1 100%;
                                }
                            }
                        }
                    }
                }

                & > .model-info {
                    display: flex;
                    flex-wrap: wrap;

                    & > .title {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 26px;
                        color: #FFFFFF;
                        text-transform: capitalize;
                        margin-top: 23px;
                        display: inline-flex;
                        margin-right: 20px;
                    }

                    & > .model-tags {
                        display: inline-flex; 
                        align-items: end;

                        & > ul {
                            text-align: left;

                            & > li {
                                background: #45B9FF;
                                border-radius: 4px;
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 14px;
                                color: #001E30;
                                height: 21px;
                                padding: 0 6px;
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                margin: 5px 5px 0 0;
                                text-transform: lowercase;
                                transition: all linear 100ms;
                            }
                        }
                    }

                    & > .description {
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        color: #FFFFFF;
                        margin: 10px 0 30px;
                        flex: 1 1 100%;
                    }
                }

                & > .model-container {
                    max-height: calc(100vh - 260px);
                    overflow-y: auto;
                    overflow-x: hidden;
                    margin-right: -2px;
                    padding-right: 2px;

                    & > .context {
                        position: relative;
    
                        & > .section-heading {
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 19px;
                            color: #FFFFFF;
                        }
    
                        & > .context-box {
                            min-height: 90px;
                            background: transparent;
                            border-radius: 6px;
                            font-size: 14px;
                            line-height: 17px;
                            color: #FFFFFF;
                            padding: 20px;
                            outline: none;
                            border: 0.75188px solid #577692;
                            width: 100%;
                            font-family: inherit;
                            margin: 15px 0 30px;
                            resize: none;

                            &:focus {
                                border: 1px solid #45B9FF;
                            }
                        }
    
                        & > .words-count {
                            position: absolute;
                            bottom: 38px;
                            right: 11px;
                            color: #577692;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 9.02256px;
                            line-height: 11px;
                        }
                    }
    
                    & > .chat-box-container {
    
                        & > .chat-box {
                            height: calc(100vh - 500px);
                            max-height: calc(100vh - 500px);
                            overflow-y: auto;
                            padding-right: 5px;
                            margin-bottom: 30px;
    
                            & > ul {
                                display: flex;
                                flex-direction: column;
    
                                & > li {
                                    min-height: 36px;
                                    background: #FFFFFF;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 1.25;
                                    color: #001E30;
                                    margin-bottom: 12px;
                                    border-radius: 4.51128px;
                                    display: flex;
                                    align-items: center;
                                    max-width: 65%;
                                    align-self: flex-end;
                                    position: relative;
                                    margin-right: 54px;
                                    padding: 9px 12px;
    
                                    &::before {
                                        content: "";
                                        background: url('../images/dummy/dummy-user-chat.svg') no-repeat;
                                        width: 36px;
                                        height: 36px;
                                        background-size: 36px;
                                        position: absolute;
                                        right: -54px;
                                        top: 0;
                                    }
    
                                    &::after {
                                        content: "";
                                        background: url('../images/chat-message-right.svg') no-repeat;
                                        width: 11.65px;
                                        height: 8.27px;
                                        background-size: 11.65px 8.27px;
                                        position: absolute;
                                        right: -6px;
                                        top: 0;
                                    }
    
                                    &.ai-bot,
                                    &.dialogue-loader {
                                        background: #45B9FF;
                                        color: #001E30;
                                        align-self: flex-start;
                                        margin-left: 54px;
    
                                        &::before {
                                            content: "";
                                            background: url('../images/deepsage-bot.svg') no-repeat;
                                            width: 36px;
                                            height: 36px;
                                            background-size: 36px;
                                            position: absolute;
                                            left: -54px;
                                            top: 0;
                                        }
    
                                        &::after {
                                            content: "";
                                            background: url('../images/chat-message-left.svg') no-repeat;
                                            width: 11.65px;
                                            height: 8.27px;
                                            background-size: 11.65px 8.27px;
                                            position: absolute;
                                            left: -5px;
                                            top: 0;
                                        }
                                    }
    
                                    &.dialogue-loader {
                                        padding: 0 30px;
                                    }
    
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
    
                        & > .dsg-form {
    
                            & > .form-field {
                                margin: 0;
                                display: flex;
    
                                & > .form-control {
                                    width: calc(100% - 45px);
                                    height: 37px;
                                    border-radius: 6px;
                                    padding: 0 11px;
                                }
    
                                & > .submit-text {
                                    padding: 0;
                                    margin: 0;
                                    background: none;
                                    outline: none;
                                    border: none;
                                    margin-left: 10px;
                                }
                            }
                        }
                    }
    
                    & > .generation {
                        & > .section-heading {
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 19px;
                            color: #FFFFFF;
                        }

                        & > .text-box {
                            min-height: 280px;
                            max-height: 280px;
                            // background: #FFFFFF;
                            background-color: transparent;
                            border-radius: 8px;
                            font-size: 14px;
                            line-height: 1.25;
                            color: #577692;
                            padding: 20px;
                            outline: none;
                            width: 100%;
                            font-family: inherit;
                            margin: 15px 0 30px;
                            resize: none;
    
                            &::placeholder {
                                color: #577692;
                                opacity: 1;
                            }
                        }

                        .backdrop {
                            overflow: auto;
                            background-color: #fff;
                            position: absolute;
                            z-index: -1;
                        }
                          
                        .highlights {
                            white-space: pre-wrap;
                            word-wrap: break-word;
                            color: transparent;
                        }

                        mark {
                            color: transparent;
                            background-color: #d4e9ab; /* or whatever */
                        }
                    }
    
                    & > .generate-btn-container {
                        text-align: center;
    
                        & > .btn-generate {
                            background: #45B9FF;
                            border-radius: 12px;
                            height: 60px;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 19px;
                            color: #FFFFFF;
                            border: none;
                            padding: 0 30px;
                        }
                    }

                }
            }
        }
    }
}

.generate-loader {
    position: relative;
    text-align: center;
    height: 60px;
    overflow: hidden;


    & > img {
        width: 210px;
        // mix-blend-mode: exclusion;
        position: absolute;
        top: -65px;
        left: calc(50% - 105px);
        z-index: -1;
    }

    & > .loader-text {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #FFFFFF;
        position: absolute;
        width: 100%;
        top: 35px;
        left: 5px;
    }
}

.dot-flashing {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #FFFFFF;
    color: #FFFFFF;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;

    &::before,
    &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
    }

    &::before {
        left: -12px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #FFFFFF;
        color: #FFFFFF;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 0s;
    }

    &::after {
        left: 12px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #FFFFFF;
        color: #FFFFFF;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 1s;
    }
}
  
@keyframes dotFlashing {
    0% {
        opacity: 0.4;
    }
    50%,
    100% {
        opacity: 1;
    }
}

@include media-breakpoint-up(lg) { 
    .dsg-search-component {
        & > .main {

            & .search-container {
                max-width: calc(100% - 400px);
            }

            & > .search-results-container {

                & > .filters,
                & > .recently-used-models {
                    flex: 0 0 230px;
                }

                & > .filters {

                    & > .general-settings {

                        & > .dsg-form {

                            & > .form-field {
                                width: 40px;
                            }
                        }
                    }
                }

                & > .recently-used-models {
                    padding-right: 40px;
                }

                & > .results {
                    padding: 0 30px;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .dsg-search-component {
        & > .main {

            & .search-container {
                max-width: calc(100% - 450px);
            }

            & > .search-results-container {

                & > .filters,
                & > .recently-used-models {
                    flex: 0 0 261px;
                }

                & > .filters {

                    & > .general-settings {

                        & > .dsg-form {

                            & > .form-field {
                                width: 50px;
                            }
                        }
                    }
                }

                & > .recently-used-models {
                    padding-right: 40px;
                }

                & > .results {
                    padding: 0 47px;
                }
            }
        }
    }
}

@include media-breakpoint-up(xxl) {
    .dsg-search-component {
        & > .main {

            & .search-container {
                max-width: calc(100% - 500px);
            }

            & > .search-results-container {

                & > .filters,
                & > .recently-used-models {
                    flex: 0 0 300px;
                }

                & > .filters {

                    & > .general-settings {

                        & > .dsg-form {

                            & > .form-field {
                                width: 57px;
                            }
                        }
                    }
                }

                & > .recently-used-models {
                    padding-right: 71px;
                }
            }
        }
    }
}