@import '../App.scss';

.login-component {
    position: relative;
    background: radial-gradient(188.05% 135.76% at 74.17% -20.47%, #80ABD9 0%, #CDD4E6 95.68%);
    padding: 56px 0;
    min-height: 100vh;

    .main {
        font-style: normal;
        font-weight: normal;
        position: relative;
        z-index: 0;
        background: #FAFAFA;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 20px;    
        padding: 166px 123px;
        margin: 0 194px 0 auto;
        z-index: 2;
        min-height: 570px;
        transition: 500ms all linear;

        &.slide-right {
            transform: translateX(800px);

            & + .welcome-tbbc {
                width: 350px;
                left: calc(50% - 175px);
            }
        }

        & > .lds-spinner {
            position: absolute;
            z-index: 1;
            left: calc(50% - 82px);
            top: calc(50% - 82px);
        }

        & > .section-heading {
            font-family: 'Space Mono';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #909090;
            margin-bottom: 24px;
            text-transform: uppercase;
        }

        & > .helper-text {
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #899DAB;
            margin: 15px 0 30px;
        }

        & > .dsg-form {
            & > .form-field {

                & > .form-control {
                    // padding-left: 51px;
                }

                & > .form-icon.email::before {
                    content: "";
                    background: url('../images/email.svg') no-repeat;
                    width: 20px;
                    height: 14.29px;
                    background-size: 20px 14.29px;
                    position: absolute;
                    top: 23px;
                    left: 20px;
                }
                
                & > .form-control:focus,
                & > .form-control:not([value=""]) {
                    & + .form-icon.email::before {
                        background: url('../images/email-trans.svg') no-repeat;
                    }
                }

                & > .form-control:focus,
                & > .form-control:not([value=""]) {
                    & + .form-icon.password::before {
                        background: url('../images/password-trans.svg') no-repeat;
                    }
                }

                & > .form-control:focus,
                & > .form-control:not([value=""]) {
                    & + .form-icon.organisation::before {
                        background: url('../images/org.svg') no-repeat;
                        background-size: 16.25px 20px;
                    }
                }

                & > .form-control:focus,
                & > .form-control:not([value=""]) {
                    & + .form-icon.user::before {
                        background: url('../images/username-trans.svg') no-repeat;
                        background-size: 16.25px 20px;
                    }
                }

                & > .form-icon.password::before {
                    content: "";
                    background: url('../images/password.svg') no-repeat;
                    width: 16.25px;
                    height: 20px;
                    background-size: 16.25px 20px;
                    position: absolute;
                    top: 18px;
                    left: 22px;
                }

                & > .form-icon.organisation::before {
                    content: "";
                    background: url('../images/org-trans.svg') no-repeat;
                    width: 16.25px;
                    height: 20px;
                    background-size: 16.25px 20px;
                    position: absolute;
                    top: 18px;
                    left: 22px;
                }

                & > .form-icon.user::before {
                    content: "";
                    background: url('../images/username.svg') no-repeat;
                    width: 16.25px;
                    height: 20px;
                    background-size: 16.25px 20px;
                    position: absolute;
                    top: 18px;
                    left: 22px;
                }

                &.fgt-pwd {
                    display: flex;
                    justify-content: space-between;
                    font-family: 'SpaceGrotesk';

                    & > .dsg-checkbox {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 11px;
                        line-height: 17px;
                        color: #42474E;
                        font-weight: 500;
                    }

                    & > .forgot-pwd {
                        font-weight: 500;
                        font-size: 11px;
                        line-height: 17px;
                        text-align: right;
                        color: #42474E;
                    }
                }
            }

            & > .manage-actions {
                display: flex;
                justify-content: space-around;

                & > .submit {
                    width: 165px;
                    padding: 0 30px;
                }
            }

            & > .submit {
                width: 100%;
                margin-top: 52px;
            }

            & > .another-login-option {
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: #899DAB;
                padding: 17px 0;
            }

            & > .google-login {
                background-color: #FFFFFF;
                font-size: 16px;
                line-height: 19px;
                color: #0C3351;
                display: flex;
                justify-content: center;
                align-items: center;

                & > img {
                    margin-right: 11px;
                }
            }

            & > .sign-up {
                margin-top: 20px;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #899DAB;

                & > a {
                    margin-left: 4px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    display: block;
                    margin: 0;

                    & > button {
                        width: 100%;
                    }
                }
            }
        }
    }

    & > .login-banner {
        position: absolute;
        top: 200px;
        z-index: 1;
    }

    & > .login-bg-eclipse {
        position: absolute;
        bottom: 0;
        z-index: -1;
        max-width: 100%;
    }

    &.manage-account {
        padding: 0;
        padding-bottom: 50px;

        & > .dsg-header {
            margin-bottom: 40px;
            padding-bottom: 30px;
        }

        & > .main {
            padding: 100px;

            & > .section-heading {
                margin-bottom: 30px;
            }
        }
    }

    & > .welcome-tbbc {
        position: absolute;
        top: 34%;
        left: 16%;
        transition: all cubic-bezier(0.4, 0, 1, 1) 500ms;
    }
}

@include media-breakpoint-down(sm) {
    .login-component {
        .main {
            width: auto;
            padding: 80px 40px 60px;
        }
    }
}

@include media-breakpoint-down(md) {
    .login-component {
        padding-bottom: 80px;
        
        .main {
            width: 80%;
            margin: 0 auto;

            & > .lds-spinner {
                left: calc(50% - 40px);
                top: calc(50% - 40px);
            }
        }

        & > .login-banner {
            display: none;
        }
    }
}

@include media-breakpoint-up(md) {
    .login-component {
        .main {
            width: 315px;
            margin-right: 95px;

            & > .lds-spinner {
                left: calc(50% - 50px);
                top: calc(50% - 50px);
            }
        }

        & > .login-banner {
            width: 380px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .login-component {
        .main {
            width: 400px;
            margin-right: 194px;

            & > .lds-spinner {
                left: calc(50% - 60px);
                top: calc(50% - 60px);
            }
        }

        & > .login-banner {
            width: 450px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .login-component {
        .main {
            width: 480px;
            margin-right: 194px;

            & > .lds-spinner {
                left: calc(50% - 65px);
                top: calc(50% - 65px);
            }
        }

        & > .login-banner {
            width: 500px;
        }
    }
}

@include media-breakpoint-up(xxl) {
    .login-component {
        .main {
            width: 554px;

            & > .lds-spinner {
                left: calc(50% - 82px);
                top: calc(50% - 82px);
            }
        }

        & > .login-banner {
            width: auto;
        }
    }
}