.grid-container {
  // display: flex;
  // flex-direction: column;
  // height: 240px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 440px;
  margin-top: 40px;
  column-gap: 24px;

  .card {
    height: 240px;
    border: 0px;
    background-color: transparent;
  }

  .category-card {
    height: 160px;
    border-radius: 8px;
    cursor: pointer;
  }

  .category-heading {
    font-family: "Segoe UI";
    height: 28px;
    margin-top: 12px;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    color: #000000;
  }
}
