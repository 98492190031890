.modal {
    &>.modal-dialog.product-pricing-component {
        &>.modal-content {
            width: 840px;


            &>.modal-header {
                padding-left: 24px;


                &>.modal-title {
                    font-family: SpaceGrotesk;
                    line-height: 28px;
                    text-align: left;




                    &>.h3 {
                        font-size: 22px;
                        font-weight: 400;
                        color: rgba(26, 28, 30, 1);
                    }


                    &>.p {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(66, 71, 78, 1);
                    }
                }
            }


            &>.modal-body {
                margin-left: 24px;


                &>.title {
                    font-family: SpaceGrotesk;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 28px;
                    text-align: left;
                    color: rgba(0, 29, 50, 1);
                }


                &>.product {
                    margin-top: 24px;
                    font-family: SpaceGrotesk;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    color: rgba(0, 29, 50, 1);
                }


                &>.cards {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;


                    &>.payment {
                        width: 383px;
                        height: 180px;
                        padding: 16px 10px 11px 10px;
                        border-radius: 8px;
                        border: 2px solid rgba(252, 252, 255, 1);
                        background: rgba(252, 252, 255, 1);
                        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
                        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);


                        &:hover {
                            cursor: pointer;
                        }


                        &.oneTimePayment,
                        &.subscription {
                            border: 2px solid rgba(130, 166, 217, 1)
                        }


                        &>.card-title {
                            font-family: SpaceGrotesk;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 24px;
                            text-align: center;
                            color: rgba(0, 0, 0, 1);
                            padding-top: 23.5px;
                        }


                        &>.subscription {
                            padding: 10px 82px;
                        }


                        p {
                            padding: 10px 70px;
                            font-family: Space Mono;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                            text-align: center;
                        }


                    }
                }


                &>.edit-cost {
                    width: 180px;
                    height: 66px;
                    gap: 20px;
                    padding: 16px 10px 11px 10px;
                    margin-top: 16px;
                    border-radius: 8px;
                    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
                    background: rgba(252, 252, 255, 1);
                    border-color: transparent;
                    font-family: Space Mono;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    color: rgba(0, 0, 0, 1);
                }


                &>.toggle {
                    display: flex;
                    font-family: SpaceGrotesk;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: center;
                    color: rgba(81, 96, 111, 1);


                    p {
                        //padding-top: 20px;
                        padding: 10px 0px 0px 10px;
                    }
                }


                &>.review {
                    margin-top: 22px;
                    font-family: Space Mono;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    text-decoration-line: underline;
                    color: rgba(81, 96, 111, 1);
                }
            }


            &>.modal-footer {
                justify-content: space-between;
                margin-top: 32px;


                &>.hg-button {
                    &>.btn.btn-secondary {
                        color: rgba(0, 99, 154, 1);
                    }
                }
            }
        }
    }
}
