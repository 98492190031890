.manage-versions-container {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  padding: 2em;
  width: 55vw;
  min-height: 30vh;
  max-height: 110vh;
  gap: 32px;
  // font-family: Arial, sans-serif;
  background: rgba(243, 243, 246, 1);
  display: flex;
  flex-direction: column;
  .versions-container {
    min-height: 10vh;
    height: auto;
    margin-bottom: 2em;
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  .manage-versions-desc {
    //styleName: BigBlue/body/medium;
    // font-family: Space Mono;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(66, 71, 78, 1);
    width: 792px;
    height: 40px;
    gap: 0px;

    align-self: self-start;
  }

  h2 {
    background: rgba(243, 243, 246, 1);
    //styleName: BigBlue/headline/small;
    // font-family: Space Grotesk;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    align-self: start;
  }
}

.manage-versions-upload-section {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
  align-self: flex-start;
}

.manage-versions-upload-section button {
  padding: 0.6em 1em;
  background-color: transparent;
  color: rgba(130, 166, 217, 1);
  cursor: pointer;
  border: 1px solid rgba(130, 166, 217, 1);
  border-radius: 2em;
}

.manage-versions-version-item {
  margin-top: 1em;
  width: 100%;
  padding-bottom: 1.5em;
  border-bottom: 1px solid rgba(114, 119, 127, 1);
}

.manage-versions-version-info {
  display: flex;
  justify-content: space-between;
  p {
    font-size: 1rem;
    color: #717171;
  }
  .manage-versions-info-divs {
    display: flex;
    flex-direction: column;
    .manage-versions-info-details {
      //styleName: BigBlue/body/medium;
      // font-family: Space Mono;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: black;
    }
  }
}

.manage-versions-info-titles {
  //styleName: BigBlue/body/medium;
  // font-family: Space Mono;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(81, 96, 111, 1);
  margin-bottom: 0.3em;
}

.manage-versions-version-actions {
  display: flex;
  flex-direction: column;
  button {
    cursor: pointer;
    font-size: 0.9rem;
    //styleName: BigBlue/body/medium;
    // font-family: Space Mono;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(0, 99, 154, 1);
    border: none;
    padding: 0;
    background-color: transparent;
    margin-right: 2em;
  }
  .manage-versions-delete-button {
    //styleName: BigBlue/body/medium;
    // font-family: Space Mono;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: rgba(0, 0, 0, 1);
    margin-left: 4em;
    margin-right: 0;
  }
}
.manage-versions-rename-section {
  display: flex;
  flex-direction: column;
  input {
    display: inline;
    width: 50%;
    border-radius: 0.5em;
    border: 1px dashed rgba(81, 96, 111, 1);
    height: 4em;
    margin-top: 0.5em;
  }
  div {
    display: flex;
  }

  button {
    border-radius: 1.5em;
    height: fit-content;
    align-self: center;
    padding: 0.3em 0.8em;
    background-color: transparent;
    border: 1px solid rgba(130, 166, 217, 1);
    color: rgba(130, 166, 217, 1);
    margin-left: 1em;
  }
  label {
    //styleName: BigBlue/body/medium;
    // font-family: Space Mono;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(81, 96, 111, 1);
  }
}

.manage-versions-toggle-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.manage-versions-toggle-section label {
  display: flex;
  align-items: center;
  gap: 10px;
  //styleName: BigBlue/label/large;
  // font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: rgba(81, 96, 111, 1);
}

.manage-versions-toggle-section input {
  cursor: pointer;
}

.manage-versions-save-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.manage-versions-save-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.manage-versions-save-buttons .cancel-btn {
  background-color: #f8f9fa;
  color: #333;
}

.manage-versions-save-buttons {
  .manage-versions-save-btn {
    background: rgba(0, 99, 154, 1);
    color: white;
    border-radius: 1.5em;
  }
  .manage-versions-cancel-btn {
    background-color: transparent;
    border-radius: 30px;
    color: rgba(0, 99, 154, 1);
  }
}

.manage-versions-toggle-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
