.errorMsgnew {
    min-height: 56px;
    height: auto;
    background: #FFF0EE;
    border-radius: 8px;    
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: "Space Mono";
    letter-spacing: 0.25px;
    color: #BA1A1A;
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 8px 40px 8px 16px;
    flex-wrap: wrap;
    position: relative;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    border-radius: 8px;

    &.hide {
        display: none;
    }

    &> img {
        position: absolute;
        right: 8px;
        top: 14px;
        cursor: pointer;
    }
}