.dsg-dropdown {
  width: 100%;
  position: relative;
  flex: 1 0 100%;

  .dropdown-toggler {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .selected-item {
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.35px;
    cursor: pointer;
    border-bottom: 1px solid #577692;
    box-sizing: border-box;
    background: transparent;
    color: #212529;
    height: 60px;
    width: 100%;
    padding: 0 28px 0 15px;

    &::before {
      content: "";
      width: 24px;
      height: 24px;
      background: url("../images/arrow-down.svg") no-repeat;
      background-size: 24px;
      position: absolute;
      right: 5px;
      top: calc(50% - 12px);
      transition: all 250ms linear;
    }

    // &.open {
    //   box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.08);
    //   border-bottom: none;
    //   border-radius: 6px 6px 0 0;
    //   box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    //     0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    // }

    &.open::before {
      transform: rotate(-180deg);
    }
  }

  .dropdown-list {
    background: #ffffff;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 10px 10px;
    position: absolute;
    width: 100%;
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: #000000;
    z-index: 1;
    transition: all 250ms linear;

    ul {
      max-height: 320px;
      overflow-y: auto;
      border-radius: 0px 0px 8px 8px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
        0px 4px 8px 3px rgba(0, 0, 0, 0.15);
      scrollbar-width: none;

      li {
        display: flex;
        align-items: center;
        height: 40px;
        text-transform: capitalize;
        cursor: pointer;
        // border-top: 1px solid #577692;
        transition: all linear 150ms;
        padding: 0 20px;
        background: #fff;

        &:hover {
          background: #1d1b2014;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.search-field {
  position: sticky;
  top: 0;
  background: white;
  padding: 10px;

  & > .form-field {
    position: relative;
    margin-bottom: 0;

    & > .form-control {
      // padding-left: 49px;
      // background: #F6F6F6;
      height: 40px;
      // border-radius: 25px;
      // width: calc(100% - 15px);

      &:focus {
        box-shadow: none;
      }
    }

    .search-icon {
      position: absolute;
      top: 15px;
      left: 20px;
    }
  }
}
