@import "bootstrap/dist/css/bootstrap.min.css";

@font-face {
  font-family: "SpaceGrotesk-Bold";
  src: url(./fonts/SpaceGrotesk-Bold.ttf);
}

@font-face {
  font-family: "SpaceGrotesk-Light";
  src: url(./fonts/SpaceGrotesk-Light.ttf);
}

@font-face {
  font-family: "SpaceGrotesk-Medium";
  src: url(./fonts/SpaceGrotesk-Medium.ttf);
}

@font-face {
  font-family: "SpaceGrotesk";
  src: url(./fonts/SpaceGrotesk-Regular.ttf);
}

@font-face {
  font-family: "SpaceGrotesk-SemiBold";
  src: url(./fonts/SpaceGrotesk-SemiBold.ttf);
}

@font-face {
  font-family: "SpaceMono-Bold";
  src: url(./fonts/SpaceMono-Bold.ttf);
}

@font-face {
  font-family: "SpaceMono-BoldItalic";
  src: url(./fonts/SpaceMono-BoldItalic.ttf);
}

@font-face {
  font-family: "SpaceMono-Italic";
  src: url(./fonts/SpaceMono-Italic.ttf);
}

@font-face {
  font-family: "Space Mono";
  src: url(./fonts/SpaceMono-Regular.ttf);
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Regular.ttf);
}

body {
  margin: 0;
  font-family: 'Space Mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: #2c3135;
  background-color: #FAFAFA;
}

body::-webkit-scrollbar-thumb {
  background-color: #2c3135;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

input:focus,
input:hover,
button:focus,
textarea:focus,
audio:focus,
video:focus {
  outline: 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

input:invalid {
  box-shadow: none;
}

a,
a:hover,
a:visited,
a:focus {
  text-decoration: none;
  color: inherit;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 /* -webkit-box-shadow: 0 0 0 30px #0C3351 inset !important; */
}

/*Change text in autofill textbox*/
input:-webkit-autofill
{
 /* -webkit-text-fill-color: #FFFFFF !important; */
}


ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  cursor: pointer;
  font-family: 'SpaceGrotesk';
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.text-truncate {
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pd-8 {
  padding: 8px;
}

@keyframes rotate-loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
