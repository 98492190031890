 

.modal{
    &>.manage-versions-modal{
        & > .modal-content{
            width: fit-content;
            border-radius: 1.2em;
        }
    }
}
