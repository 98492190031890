.img-title-container {
  display: flex;
  justify-content: start;
  width: 380px;
  gap: 1em;
  margin: 0;
  padding: 0;

  >.img-section{
    width: 72px;
    height: 72px;
    padding: 0;
    margin-left: -0.5em;

    >.category {
      width: 60px;
      height: 60px;
      border-radius: 3px;
    background: radial-gradient(116.71% 85% at 50% 50%, #80ABD9 0%, #CDD4E6 95.68%);
    }
  }
  >.heading-container{
    padding-top: 0.2em;
    > .main-heading {
        //styleName: BigBlue/headline/small;
        // font-family: Space Grotesk;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        text-align: left;
        color: rgba(0, 0, 0, 1);
      }
    
      > .sub-heading {
        //styleName: BigBlue/title/small;
        // font-family: Space Grotesk;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: rgba(114, 119, 127, 1);
      }
  }
  
}
