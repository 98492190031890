.product-header-div {
  height: 60px;
  margin-left: 5.8%;
  align-items: center;
  margin-bottom: 4em;
  margin-top: 32px;
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  padding-left: 1em;
  top: 0;
  z-index: 30;
  background: #fafafa;
}
.small-seperation-line {
  border: 1px 0px 0px 0px;
  border: 1px solid rgba(205, 212, 230, 1);
  margin-top: 1em;
}
.product-preview-container {
  width: 58.5rem;
  height: 380px;
  // zoom: 111.5%;
  top: 1062px;
  left: 132px;
  padding: 0px 16px 16px 0px;
  gap: 8px;
  border-radius: 8px 0px 8px 8px;
  overflow: hidden;
  overflow-y: auto;
}
.product-lower-container {
  margin-top: 1em;
  .product-popular-datasets-grid {
    display: grid;
    // zoom:70%;
    grid-template-columns: repeat(3, 1fr);
    max-height: 200px;
    margin-top: 40px;
    margin-left: 6.5em;
    column-gap: 24px;
    // row-gap: 88px;
    overflow-y: hidden;
  }
  .product-popular-datasets-list {
    display: grid;
    grid-template-columns: 1fr;
    //display: flex;
    // zoom:70%;
    max-height: 300px;
    margin-top: 40px;
    overflow-y: hidden;
  }
  .product-seperation-line {
    margin-top: 27px;
    margin-bottom: 38px;
    gap: 0px;
    width: 85vw;
    margin-left: 6em;
    border: 1px 0px 0px 0px;
    border: 1px solid rgba(205, 212, 230, 1);
  }

  .product-datasets {
    display: flex;
    justify-content: space-between;
    height: 20px;
    padding-left: 6em;
    padding-right: 6em;
    gap: 10px;

    & > .product-heading {
      width: 400px;
      height: 20px;
      // font-family: spaceGrotesk;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: rgba(69, 69, 69, 1);
    }

    & > .product-view-all {
      display: flex;
      width: 120px;
      height: 20px;
      left: 1008px;
      gap: 3px;

      .product-view-all-text {
        // font-family: spaceGrotesk;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: right;
        color: rgba(194, 199, 207, 1);
      }

      .product-next-icon {
        width: 6.18px;
        height: 10px;
        top: 5px;
        left: 6.91px;
        margin-top: -4px;
        color: rgba(194, 199, 207, 1);
      }
    }
  }
}
#hr {
  border: 1px solid rgba(205, 212, 230, 1);
}
.product-view-license-button-container {
  height: auto;
  align-self: last baseline;
  margin-bottom: 16px;
}
.product-main-content {
  margin-left: 2em;
}
.product-sidebar-section {
  width: 5%;
  padding: 2em;
  padding-top: 0;
}

.product-card {
  display: flex;
  flex-direction: column;
  padding: 14px 16px;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  min-height: 80px;
  // min-width: 250px;
  height: Hug (72px) px;
  > .title {
    //styleName: BigBlue/label/small;
    // font-family: Space Grotesk;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: rgba(26, 28, 30, 1);
    margin-bottom: 0;
  }
  > .desc {
    //styleName: BigBlue/title/large;
    // font-family: Space Grotesk;
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: rgba(26, 28, 30, 1);
    margin-bottom: 0;
  }
}
.modal-container {
  .modal-content {
    width: 600px;
  }
}

.product-more-dataset-description {
  display: none;
}
.product-show-more {
  display: inline;
  // font-family: Space Mono;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(69, 69, 69, 1);
  width: 600px;
  height: 100px;
  top: 328px;
  left: 130px;
  gap: 0px;
}

.product-cards-description {
  display: flex;
  gap: 8px;
}

.mini-card {
  min-width: 130px;
  min-height: 72px;
  padding: 14px 16px;
  border-radius: 8px;
}

.product-dataset-title {
  // font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(69, 69, 69, 1);
  margin-top: 2em;
}
.product-dataset-description {
  //styleName: BigBlue/body/medium;
  // font-family: Space Mono;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(69, 69, 69, 1);
  width: 600px;
  min-height: 100px;
  top: 328px;
  left: 130px;
  gap: 0px;
  height: fit-content;
}
.product-main-content {
  //   background-color: aquamarine;
  width: 95%;
}

.product-tag-item {
  width: Hug (144px) px;
  height: Hug (28px) px;
  top: 565px;
  left: 131px;
  padding: 4px 10px 4px 10px;
  gap: 10px;
  border-radius: 30px;
  background: rgba(221, 232, 244, 1);
  //styleName: BigBlue/body/data/Small;
  // font-family: Space Mono;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.product-information-categories-tags-container {
  display: flex;
  max-width: 600px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.product-title-section {
  //styleName: BigBlue/title/medium;
  // font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: rgba(130, 130, 130, 1);
  margin-left: 0.5em;
  margin-bottom: 2em;
}

.product-lower-main {
  display: flex;
  width: 95%;
  .product-lower-left-main {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 0;
    padding-left: 0;

    .product-dataset-info {
      width: 100%;
    }

    .product-dataset-info > hr {
      width: 100%;
    }
  }

  .lower-right-main {
    width: 30%;
    padding: 1em;
    padding-left: 5em;
    .gnu-public-license {
      margin-bottom: 1em;
    }
    .gnu-description {
      padding: 1em;
      width: 263px;
      height: 90px;
      border-radius: 10px;
      background: rgba(245, 245, 245, 1);
      color: rgba(130, 130, 130, 1);
      // font-family: Space Mono;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      display: flex;
      gap: 1em;
    }
    .contact-section {
      margin-top: 5em;

      & > p {
        // font-family: Space Grotesk;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        color: rgba(81, 96, 111, 1);
      }

      & > ul {
        // font-family: Space Mono;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: rgba(81, 96, 111, 1);
        text-decoration: underline;
      }

      & > ul {
        margin-left: 4em;
      }
      & > ul > li {
        margin-top: 0.5em;
      }
      & > p {
        margin-left: 4.55em;
        margin-bottom: 0;
      }
    }
  }
}

.gnu-card {
  width: 263px;
  height: 201px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);

  border-radius: 1em;

  .top-section {
    width: 100%;
    height: 75%;
    background: rgba(206, 221, 237, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5em 0.5em 0 0;

    .gnu-title {
      width: 100%;
      //styleName: BigBlue/headline/small;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      text-align: center;
      color: rgba(0, 29, 50, 1);
      align-self: center;
    }
    .license-text {
      width: fit-content;
      border: none;
      height: 30px;
      // font-family: Space Mono;
      font-size: 11px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: rgba(81, 96, 111, 1);
      align-self: center;
      background-color: white;
      padding: 0.5em 1em;
      border-radius: 1.5em;
    }
  }

  .lower-section {
    height: 25%;
    width: 100%;
    background-color: rgba(247, 247, 247, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 0.5em 0.5em;

    .add-to-datasets-text {
      width: fit-content;
      height: 70%;
      font-size: 12px;
      border-radius: 40px;
      background: rgba(0, 99, 154, 1);
      color: rgba(255, 255, 255, 1);
      //styleName: BigBlue/body/small;
      // font-family: Space Mono;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      padding: 0.8em 1.8em;
    }
  }
}
