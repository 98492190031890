.publishing-container {
  margin: 30px 60px 0px 80px;
  & > .publishing-heading {
    font-size: 16px;
  }
  & > .publishing-header-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    height: 48px;
    & > .publishing-section-control {
      font-size: 22px;
      font-weight: 400;
      line-height: 18px;
      & > button {
        border: none;
        background: transparent;
        margin-right: 75px;
        padding: 8px 0px;
      }
    }
    & > .publish-new {
      & > button {
        padding: 4px 10px;
        color: white;
        background-color: #82a6d9;
        border-radius: 30px;
        border: none;
        text-size-adjust: 10px;
        cursor: pointer;
      }
      & > button:hover {
        background-color: #86a7d4;
      }
    }
  }
  & > .rendered-component {
    padding: 30px 0px;
  }
}
