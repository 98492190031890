@import '../App.scss';

.header {
    text-align: left;
    position: relative;
    padding: 30px;
}

.btn-primary  {
    height: 60px;
    background: linear-gradient(180deg, #E96F00 21.59%, #FF0000 187.59%);
    border-radius: 60px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    border: none;
    padding: 0 49px;
}

.add-content {
    position: relative;
}

.password-toggle-icon {
    float: right;
    position: relative;
    right: 10px;
    top: -43px;
    cursor: pointer;
}

.close-button {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -78px;
    right: 0;
    cursor: pointer;
}

.hg-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);

    .popup-wrapper {
        width: 780px;
        height: 564px;
        padding: 60px 40px;
        background: #FFFFFF;
        box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.1);
        border-radius: 10px;

        .primary-text {
            font-family: UbuntuLight;
            font-style: normal;
            font-weight: 300;
            font-size: 48px;
            line-height: 60px;
            text-align: center;
            color: #383B42;
            margin-top: 60px;
        }

        .secondary-text {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.21875px;
            color: #383B42;
            margin-top: 10px;
        }

        .actions {
            margin-top: 40px;
            display: flex;
            justify-content: center;

            & .btn.btn-primary,
            & .btn.btn-secondary {
                width: 180px;
            }

            & .btn.btn-secondary {
                margin-right: 20px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    
}

@include media-breakpoint-up(sm) {
    
}

@include media-breakpoint-up(md) {
    
}

@include media-breakpoint-up(lg) {
    
}

@include media-breakpoint-up(xl) {
    
}

@include media-breakpoint-up(xxl) {
    
}
