.pulish-filters {
  display: flex;

  & > .search-input {
    position: relative;
    & > input {
      width: 424px;
      height: 40px;
      border: 1px solid rgba(114, 119, 127, 1);
      border-radius: 30px;
      margin-bottom: 10px;
      padding: 0px 30px;
    }
    & > .search-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-100%, -70%);
    }
  }

  &>.dropdown-section {
    display: flex;

    &>.filter-dropdown {
      width: 180px;
      display: flex;
      flex-direction: column;
      margin-left: 40px;
      justify-content: center;

      &>lable {
        font-size: 11px;
        font-weight: 600;
        margin-left: 5px;
      }

      &>select {
        border: none;
        background: transparent;
        cursor: pointer;
      }

      &>.custom {
        &:hover {
          cursor: pointer;
        }

        &>.dropdown {
          display: flex;
          justify-content: space-between;

        }
      }
    }

    &>.dropdown-option {
      width: 180px;
      padding: 5px 10px;
      position: absolute;
      margin-top: 50px;
      margin-left: 40px;
      z-index: 99;
      border-radius: 0px 0px 4px 4px;
      background-color: #fcfcff;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .3), 0 1px 3px 1px rgba(0, 0, 0, .15);

      &>.dropdown-item {
        &:hover {
          background-color: rgba(216, 211, 211, 0.827);
          color: rgba(66, 71, 78, 1);
          cursor: pointer;
        }
      }
    }
  }
}

.pagination {
  margin: 40px 0px;
  display: flex;
  justify-content: center;
  position: relative;
}