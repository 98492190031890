@import 'variables';

.hg-button,
.hg-button-icon {

    &>.btn {
        position: relative;
        height: 32px;
        border-radius: 18px;
        color: $big-blue-sys-light-surface-container-lowest;
        text-align: center;
        font-size: 12px;
        font-family: SpaceGrotesk;
        font-weight: 500;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-transform: uppercase;

        &>.icon {
            position: absolute;
            height: 18px;
            width: 18px;
            top: 14px;
            left: 20px;
        }

        &.btn-secondary {
            background: linear-gradient(180deg, #484C53 0.33%, #2A2D34 98.98%);
        }

        &.btn-primary {
            border-radius: 18px;
            opacity: 0.8999999761581421;
            background: #000;
        }
    }
}

.hg-button-icon {
    &>.btn {
        padding: 0 31px 0 53px;
    }
}

.back-to-main {
    cursor: pointer;
}