.dsg-form {
    position: relative;

    .form-field {
        margin-bottom: 20px;
        font-style: normal;
        font-weight: normal;
        text-align: left;
        position: relative;

        &.no-margin {
            margin-bottom: 0;
        }

        .form-field-label {
            color: #ffffff;
            margin-bottom: 10px;
            font-weight: 600;
            color: #49454F;
            font-weight: 500;

            &.mui {
                color: #42474E;
                font-weight: 400;
                margin: 0;
                font-size: 12px;
                line-height: 16px;
                display: inline;
                position: absolute;
                top: -9px;
                left: 13px;
                background: #FAFAFA;
                padding: 0 4px;
            }
        }

        .form-label {
            display: block;
            font-family: 'Space Mono';
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            text-transform: lowercase;
            color: #484C53;
            margin-bottom: 15px;
        }

        .form-control {
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 5px;
            width: 100%;
            height: 60px;
            padding: 1px 15px;
            background-color: transparent;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            // color: #FFFFFF;
            font-family: 'Space Mono';

            &:focus {
                border: 1px solid #000000;
                box-shadow: none;
            }

            &.border-bottom-only {
                border: none;
                border-bottom: 1px solid #000000;
                border-radius: 0;
            }
        }

        .form-control::placeholder {
            letter-spacing: 0.35px;
            opacity: 0.5;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #577692;
        }

        .form-icon {
            position: absolute;
            left: 0;
        }

        label {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.89px;
            color: #231F20;

            &.dsg-checkbox {
                margin-left: 30px;
                cursor: pointer;
            }

            &.dsg-checkbox::before {
                content: '';
                background: url('../images/checkbox.svg') no-repeat;
                position: absolute;
                left: 0;
                top: -2px;
                width: 20px;
                height: 20px;
                background-size: 18px;
            }
        }

        input:checked {
            & + label {
                &.dsg-checkbox::before {
                    background: url('../images/checkbox-checked.svg') no-repeat;
                }
            }
        }

        input:disabled {
            cursor: not-allowed;
        }
    }

    .submit {
        height: 60px;
        background: #000000;
        border-radius: 100px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
        border: none;
        padding: 0 49px;
    }

    .button {
        height: 60px;
        background: transparent;
        border-radius: 100px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #00639A;
        border: 1px solid #000000;
        padding: 0 49px;
    }
}

.range-slider {
    -webkit-appearance: none;
    width: 100%;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    height: 5.26px;
    background: #577692;
    border-radius: 2.63158px;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        width: 14.04px;
        height: 14.04px;
        background: #45B9FF;
        border-radius: 50%;
    }
      
    &::-moz-range-thumb {
    width: 14.04px;
    height: 14.04px;
    background: #45B9FF;
    cursor: pointer;
    }
}

textarea {

    &:active {
        border: 1px solid #45B9FF;
    }
}

textarea::placeholder {
    letter-spacing: 0.35px;
    opacity: 0.5;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #577692;
}