@import '../App.scss';
@import 'variables';

.modal {
    &>.modal-dialog.manage-access-component {
        &>.modal-content {
            width: 673px;

            &>.modal-header {
                padding-left: 32px;

                &>.modal-title {
                    font-family: SpaceGrotesk;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 32px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: rgba(26, 28, 30, 1);

                }
            }

            &>.modal-body {
                &>.sub-heading {
                    height: 60px;
                    font-family: Space Mono;
                    margin-left: 20px;
                    margin-right: 46px;
                    margin-top: 16px;
                    text-wrap: wrap;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: rgba(66, 71, 78, 1);
                }

                &>.upload-btn {
                    width: 250px;
                    height: 36px;
                    padding: 5px 18px;
                    margin-left: 20px;
                    margin-top: 48px;
                    border-radius: 30px;
                    border: 1px solid rgba(130, 166, 217, 1);
                    background-color: transparent;
                    color: rgba(130, 166, 217, 1);
                    font-family: var(--TitleSmallFont);
                    font-size: var(--TitleSmallSize);
                    font-weight: 500;
                    line-height: var(--TitleSmallLineHeight);
                    letter-spacing: var(--TitleSmallTracking);
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;

                    &:hover {
                        background-color: transparent;
                        color: rgba(130, 166, 217, 1);
                        border-radius: 30px;
                        border: 1px solid rgba(130, 166, 217, 1);
                    }
                }

                &>.search-container {
                    margin-left: 20px;
                    margin-top: 30px;
                }

                &>.user-list {
                    height: auto;
                    width: 100%;
                    margin-left: 20px;
                    margin-top: 10px;

                    &>.user-row {
                        // display: flex;
                        // justify-content: space-between;
                        display: grid;
                        grid-template-columns: 40% 20% 25% 15%;
                        height: 44px;
                        padding: 10px;
                        gap: 12px;

                        &>.user-name {
                            font-family: Space Mono;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            color: rgba(26, 28, 30, 1);
                        }

                        &>.user-role {
                            font-family: Space Mono;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            color: rgba(81, 96, 111, 1);
                        }

                        &>.edit-access {
                            font-family: Space Mono;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            text-align: center;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            color: rgba(0, 99, 154, 1);

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        &>.remove-user {
                            color: rgba(194, 199, 207, 1);

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            &>.modal-footer {

                &>.footer {
                    display: grid;
                    grid-template-columns: 10% 60% 15% 15%;
                    align-items: center;
                    width: 100%;
                    margin: 36px 10px 36px 20px;

                    &>.hg-button {

                        &>.btn {
                            padding: 10px 24px 10px 24px;
                            display: flex;
                            height: 32px;
                            padding: 6px 16px;
                            justify-content: center;
                            align-items: center;
                            gap: 8px;
                            color: $big-blue-sys-light-on-surface-variant;
                            background-color: transparent;
                            text-align: center;
                            font-size: 14px;
                            font-family: SpaceGrotesk;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            text-transform: capitalize;
                            border: none;

                            &.btn-secondary {
                                background: transparent;
                                width: 60px;
                                color: $big-blue-sys-light-primary;
                            }

                            &.btn-primary {
                                border-radius: 100px;
                                background: $big-blue-sys-light-primary;
                                color: #fff;
                                padding: 20px 24px 20px 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}