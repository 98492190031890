@import '../App.scss';
@import 'variables';

.preview-table {
    font-size: 11px;
    font-family: SpaceGrotesk;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    width: fit-content;
    position: relative;
    padding: 1px;
    background: #F3F3F6;

    &>.table-responsive {
        max-height: 85vh;
        padding: 2px;

        // .table {
        //     & thead tr:nth-child(1) th {
        //         //   background: white;
        //         position: sticky;
        //         top: 0;
        //         z-index: 10;
        //         box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
        //         border-radius: 8px 0 0 8px;
        //     }
        //     & thead tr:last-child th {
        //         //   background: white;
        //         position: sticky;
        //         top: 0;
        //         z-index: 10;
        //         box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
        //         border-radius: 0 8px 8px 0;
        //     }
        // }


        &>table {
            // border-color: #fff;
            // overflow: hidden;
            // border-collapse: collapse;
            // border-radius: 8px;
            margin-bottom: 0;

            &.table-striped>tbody>tr:nth-of-type(odd),
            &.table-striped>tbody>tr:nth-of-type(even) {
                background: $big-blue-ref-neutral-neutral-98;
                color: $big-blue-sys-light-secondary;
                font-family: SpaceGrotesk;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }

            &.table-striped>tbody>tr:nth-of-type(even) {
                background: #fff;
            }



            &.table>:not(caption)>*>* {
                padding: 0.5rem 0.5rem;
                // background-color: var(--bs-table-bg);
                border-bottom-width: 0px;
                box-shadow: none;
            }

            & tr {
                // border-radius: 8px 0px 0px 8px;
                box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
                // vertical-align: middle;
                // height: 42px;
            }

            &>thead {

                &>tr {
                    background: #fff;
                    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
                    border-radius: 8px;
                }
            }



            & th,
            & td {

                padding: 2px 0 2px 2px;

                &>div {
                    border-right: 1px solid $big-blue-ref-neutral-neutral-90;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    padding: 0 8px;
                    color: $big-blue-sys-light-secondary;
                    font-family: SpaceGrotesk;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    /* 145.455% */
                }

                &:last-child>div {
                    border-right: 0px;
                }
            }

            & th {

                &>div {
                    //   background: #fff;
                    // box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
                    // border-radius: 8px;
                    font-weight: 600;
                }
            }

            & td {

                padding: 2px 0 2px 2px;


                &>div {

                    height: auto;
                }
            }
        }

        & thead {
            // text-transform: uppercase;
        }

        & tbody {
            color: $big-blue-sys-light-secondary;
        }
    }

    &>.table-header {
        display: flex;
        border-radius: 8px;
        background: var(--big-blue-sys-light-on-primary, #FFF);
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
        height: 32px;
        align-items: center;
        color: #000;
        font-family: SpaceGrotesk;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 145.455% */
        margin-bottom: 8px;
        position: sticky;
        top: 1px;
        padding-left: 30px;

        .header-item {
            // min-width: 100px;
            // max-width: 100px;
            display: flex;
            padding: 12px 8px;
            border-right: 1px solid $big-blue-ref-neutral-neutral-90;

            &:last-child {
                border-right: none;
            }
        }
    }

    &>.table-data {
        border-radius: 8px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
        overflow: hidden;
        background: $big-blue-sys-light-surface-bright;

        & > ul {
            display: flex;
            min-height: 32px;
            align-items: center;
            padding-left: 30px;

            & > li {
                padding: 8px;
                // min-width: 100px;
                // max-width: 100px;
                padding: 8px;
                border-right: 1px solid $big-blue-ref-neutral-neutral-90;
                align-self: stretch;
                display: flex;
                align-items: center;
                word-break: break-all;

                &:last-child {
                    border-right: none;
                }
            }

            &:nth-child(odd) {
                // background: $big-blue-sys-light-surface-bright;
                // box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
            }

            &:nth-child(even) {

                & > li {
                    background: $big-blue-sys-light-on-primary;
                }
                
                // box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
            }
        }
    }

}




// .preview-table {
//     font-size: 11px;
//     font-family: SpaceGrotesk;
//     font-style: normal;
//     font-weight: 500;
//     line-height: 16px;
//     display: flex;
//     max-height: 80vh;
//     overflow: auto;

//     & > ul {
//         position: relative;

//         & > li {

//             &.table-header {
//                 border-radius: 8px;
//                 background: $big-blue-sys-light-on-primary;
//                 height: 42px;
//                 display: flex;
//                 align-items: center;
//                 padding-left: 16px;
//                 color: #000;
//                 font-family: SpaceGrotesk-bold;
//                 font-size: 11px;
//                 font-style: normal;
//                 font-weight: 500;
//                 line-height: 16px;
//                 position: sticky;
//                 top: 0;
//             }
//         }
//     }
// }