.hg-tooltip {
    display: inline-flex;
    margin-left: 4px;
    position: relative;
    line-height: 0;

    .tooltip-handler {
        cursor: help;

        &:hover+.tooltip-container {
            display: block;
        }
    }

    .tooltip-container {
        display: none;
        width: 180px;
        height: auto;
        font-family: UbuntuLight;
        font-style: normal;
        font-weight: normal;
        font-size: 11.02256px;
        line-height: 14px;
        color: #FFFFFF;
        background: #577692;
        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        position: absolute;
        left: -84px;
        bottom: 23px;
        padding: 7px 11px;
        z-index: 31;

        & > img {
            position: absolute;
            bottom: -5px;
            left: calc(50% - 7px);
        }
    }
}