@import "../App.scss";
@import "variables";

.my-datasets-component {
  & > .main {
    padding: 30px 76px 10px;

    & > .section-1 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-family: SpaceGrotesk;
      align-items: flex-start;
      justify-content: space-between;

      & > .heading {
        text-transform: uppercase;

        & > .primary {
          color: $big-blue-theme-big-blue;
          font-size: 11px;
          font-weight: 500;
          line-height: 16px;
        }

        & > .secondary {
          color: #000;
          font-size: 22px;
          line-height: 28px;
        }
      }
      & > .all-filters {
        display: flex;
        flex-direction: column;
        width: 424px;
        & > .seach-box {
          display: flex;
          // height: 39px;
          align-items: center;
          gap: 4px;
          flex-shrink: 0;

          & > .dsg-form {
            & > .form-field {
              position: relative;
              margin: 0;

              & > .form-control {
                width: 424px;
                height: 39px;
                border-radius: 20px;
                border: 1px solid $big-blue-sys-light-outline;
                padding: 0 16px;
              }

              & > .form-icon.search {
                position: absolute;
                right: 10px;
                top: 8px;
              }

              & > .form-icon.search::after {
                content: "";
                background: url("../images/search.svg");
                background-size: 24px 24px;
                width: 24px;
                height: 24px;
                position: absolute;
                top: 0;
                right: 0;
              }
            }
          }
        }
        & > .filter-selection-btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 5px;
          & > .filters {
            display: flex;
            width: 140px;
            height: 32px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-shrink: 0;
            border-radius: 16px;
            // background: $big-blue-sys-light-primary;
            // color: $big-blue-sys-light-on-primary;
            border: 1px solid $big-blue-sys-light-outline;
            color: $big-blue-sys-light-on-surface-variant;
            text-align: center;
            font-size: 14px;
            font-family: SpaceGrotesk;
            font-weight: 500;
            line-height: 20px;
            margin-right: 5px;
            cursor: pointer;
          }
          & > .dataset-actions {
            display: flex;
            width: 140px;
            height: 32px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-shrink: 0;
            border-radius: 16px;
            border: 1px solid $big-blue-sys-light-outline;
            color: $big-blue-sys-light-on-surface-variant;
            text-align: center;
            font-size: 14px;
            font-family: SpaceGrotesk;
            font-weight: 500;
            line-height: 20px;
            cursor: pointer;
          }
        }
      }

      & > .seach-box {
        display: flex;
        width: 577px;
        // height: 39px;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;
        margin-left: -80px;

        & > .dsg-form {
          & > .form-field {
            position: relative;
            margin: 0;

            & > .form-control {
              width: 424px;
              height: 39px;
              border-radius: 20px;
              border: 1px solid $big-blue-sys-light-outline;
              padding: 0 16px;
            }

            & > .form-icon.search {
              position: absolute;
              right: 10px;
              top: 8px;
            }

            & > .form-icon.search::after {
              content: "";
              background: url("../images/search.svg");
              background-size: 24px 24px;
              width: 24px;
              height: 24px;
              position: absolute;
              top: 0;
              right: 0;
            }
          }
        }
      }

      & > .filters {
        display: flex;
        width: 140px;
        height: 32px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 16px;
        // background: $big-blue-sys-light-primary;
        // color: $big-blue-sys-light-on-primary;
        border: 1px solid $big-blue-sys-light-outline;
        color: $big-blue-sys-light-on-surface-variant;
        text-align: center;
        font-size: 14px;
        font-family: SpaceGrotesk;
        font-weight: 500;
        line-height: 20px;
        margin-left: -270px;
        cursor: pointer;
      }

      .custom-dropdown {
        display: flex;
        width: 140px;
        height: 32px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 18px;
        border: 1px solid $big-blue-sys-light-outline;
        color: $big-blue-sys-light-on-surface-variant;
        text-align: left;
        font-size: 14px;
        font-family: SpaceGrotesk;
        font-weight: 500;
        line-height: 20px;
        margin-left: -120px;
        cursor: pointer;
        outline: none;
        position: relative;

        & > .selected-value {
          &::before {
            content: "";
            width: 24px;
            height: 24px;
            background: url("../images/arrow-down.svg") no-repeat;
            background-size: 24px;
            position: absolute;
            right: 5px;
            top: calc(50% - 12px);
            transition: all 250ms linear;
          }

          &.open {
            // box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.08);
            // border-bottom: none;
            // border-radius: 6px 6px 0 0;
            // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
          }

          &.open::before {
            transform: rotate(-180deg);
          }
        }

        ul {
          position: absolute;
          top: 31px;
          z-index: 2;
          width: 140px;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
            0px 4px 8px 3px rgba(0, 0, 0, 0.15);
          border-radius: 10px;
          overflow-y: auto;
          background: #ffffff;
        }

        .dropdown-toggler {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }

        ul {
          position: absolute;
          top: 31px;
          z-index: 2;
          width: 140px;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
            0px 4px 8px 3px rgba(0, 0, 0, 0.15);
          border-radius: 10px;
          overflow-y: auto;
          background: #ffffff;

          li {
            padding: 11px 16px;
            background: #fff;
            &:hover {
              background: #1d1b2014;
            }
          }
        }
      }
      .custom-dropdown1 {
        display: flex;
        width: 140px;
        height: 32px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 16px;
        border: 1px solid $big-blue-sys-light-outline;
        color: $big-blue-sys-light-on-surface-variant;
        text-align: center;
        font-size: 14px;
        font-family: SpaceGrotesk;
        font-weight: 500;
        line-height: 20px;
        position: relative;
        cursor: pointer;

        & > .selected-value {
          &::before {
            content: "";
            width: 24px;
            height: 24px;
            background: url("../images/arrow-down.svg") no-repeat;
            background-size: 24px;
            position: absolute;
            right: 5px;
            top: calc(50% - 12px);
            transition: all 250ms linear;
          }

          &.open {
            // box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.08);
            // border-bottom: none;
            // border-radius: 6px 6px 0 0;
            // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
          }

          &.open::before {
            transform: rotate(-180deg);
          }
        }

        ul {
          position: absolute;
          top: 31px;
          z-index: 2;
          width: 140px;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
            0px 4px 8px 3px rgba(0, 0, 0, 0.15);
          border-radius: 10px;
          overflow-y: auto;
          background: #ffffff;
        }

        .dropdown-toggler {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }

        ul {
          position: absolute;
          top: 31px;
          z-index: 2;
          width: 140px;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
            0px 4px 8px 3px rgba(0, 0, 0, 0.15);
          border-radius: 10px;
          overflow-y: auto;
          background: #ffffff;

          li {
            padding: 11px 16px;
            background: #fff;
            &:hover {
              background: #1d1b2014;
            }
          }
        }
      }

      & > .add-dataset {
        display: flex;
        width: 208px;
        height: 32px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 18px;
        // border: 1px solid $big-blue-sys-light-surface-tint;
        // color: $big-blue-sys-light-primary;
        background: $big-blue-sys-light-primary;
        color: $big-blue-sys-light-on-primary;
        text-align: center;
        font-size: 14px;
        font-family: SpaceGrotesk;
        font-weight: 500;
        line-height: 20px;
        position: relative;
        cursor: pointer;

        &::after {
          content: "";
          background: url("../images/plus-white.svg");
          background-size: 18px 18px;
          width: 18px;
          height: 18px;
          position: absolute;
          right: 32px;
        }
      }
      & > .add-dataset:hover {
        background-color: #347ba1;
      }

      & > .all-filters {
        display: flex;
        flex-direction: column;
        width: 424px;
        & > .seach-box {
          display: flex;
          // height: 39px;
          align-items: center;
          gap: 4px;
          flex-shrink: 0;

          & > .dsg-form {
            & > .form-field {
              position: relative;
              margin: 0;

              & > .form-control {
                width: 424px;
                height: 39px;
                border-radius: 20px;
                border: 1px solid $big-blue-sys-light-outline;
                padding: 0 16px;
              }

              & > .form-icon.search {
                position: absolute;
                right: 10px;
                top: 8px;
              }

              & > .form-icon.search::after {
                content: "";
                background: url("../images/search.svg");
                background-size: 24px 24px;
                width: 24px;
                height: 24px;
                position: absolute;
                top: 0;
                right: 0;
              }
            }
          }
        }

        & > .filter-selection-btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 5px;
          & > .filters {
            display: flex;
            width: 140px;
            height: 32px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-shrink: 0;
            border-radius: 16px;
            // background: $big-blue-sys-light-primary;
            // color: $big-blue-sys-light-on-primary;
            border: 1px solid $big-blue-sys-light-outline;
            color: $big-blue-sys-light-on-surface-variant;
            text-align: center;
            font-size: 14px;
            font-family: SpaceGrotesk;
            font-weight: 500;
            line-height: 20px;
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
    }

    & > .section-2 {
      margin-top: 11px;
      display: flex;
      flex-direction: row;
      height: 100vh;

      & > .dataset-table {
        width: 964px;
        overflow-y: scroll;
        height: 100vh;
        scrollbar-width: none;

        & > .table-header {
          display: flex;
          height: 48px;
          padding: 5px 0px 5px 9px;
          align-items: center;
          flex-shrink: 0;
          border-radius: 8px;
          background: $big-blue-sys-light-on-primary;
          box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
            0px 1px 2px 0px rgba(0, 0, 0, 0.3);
          color: $big-blue-sys-light-on-surface;
          font-size: 11px;
          font-family: SpaceGrotesk;
          font-weight: 500;
          line-height: 16px;
          text-transform: uppercase;
          position: sticky;
          z-index: 1;
          margin: 2px;

          & > div {
            height: 100%;
            border-right: 1px solid
              $big-blue-ref-neutral-variant-neutral-variant-95;
            display: flex;
            align-items: center;
            padding-left: 8px;
          }

          & > .select-all {
            padding: 0;
            border: none;

            & > label {
              font-size: 16px;
              line-height: 20px;
              letter-spacing: 0.89px;
              color: #231f20;
              position: relative;

              &.dsg-checkbox {
                margin-left: 30px;
                cursor: pointer;
              }

              &.dsg-checkbox::before {
                content: "";
                background: url("../images/checkbox-black.svg") no-repeat;
                position: absolute;
                left: -28px;
                top: -10px;
                width: 20px;
                height: 20px;
                background-size: 18px;
              }
            }

            input:checked {
              & + label {
                &.dsg-checkbox::before {
                  background: url("../images/checkbox-black-checked.svg")
                    no-repeat;
                  background-size: 18px;
                }
              }
            }
          }

          & > .dataset-name {
            width: 213px;
          }

          & > .dataset-format {
            width: 107px;
          }

          & > .organisation {
            width: 144px;
          }

          & > .creation-date {
            width: 126px;
          }

          & > .last-modified-date {
            width: 137px;
          }

          & > .dateset-size {
            width: 82px;
          }

          & > .dataset-creator {
            width: 121px;
          }
        }

        & > .table-data {
          margin-top: 7px;
          & > .pagination {
            margin-top: 10px;
            display: flex;
            justify-content: center;
            position: relative;
          }

          & > ul {
            & > li {
              display: flex;
              height: 48px;
              padding: 5px 0px 5px 9px;
              align-items: center;
              color: $big-blue-sys-light-on-surface;
              font-size: 12px;
              font-family: Space Mono;
              line-height: 16px;
              background: $big-blue-sys-light-on-primary;
              box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
                0px 1px 2px 0px rgba(0, 0, 0, 0.3);
              margin-left: 2px;
              margin-right: 2px;
              &:first-child {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
              }

              &:last-child {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
              }

              &:nth-child(even) {
                background: $big-blue-sys-light-surface-bright;
                box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
                  0px 1px 2px 0px rgba(0, 0, 0, 0.3);
              }

              &.selected {
                background: $big-blue-sys-light-surface-container;
              }

              & > .select {
                padding: 0;
                border: none;

                & > label {
                  font-size: 16px;
                  line-height: 20px;
                  letter-spacing: 0.89px;
                  color: #231f20;
                  position: relative;

                  &.dsg-checkbox {
                    margin-left: 30px;
                    cursor: pointer;
                  }

                  &.dsg-checkbox::before {
                    content: "";
                    background: url("../images/checkbox-black.svg") no-repeat;
                    position: absolute;
                    left: -28px;
                    top: -10px;
                    width: 20px;
                    height: 20px;
                    background-size: 18px;
                  }
                }

                input:checked {
                  & + label {
                    &.dsg-checkbox::before {
                      background: url("../images/checkbox-black-checked.svg")
                        no-repeat;
                      background-size: 18px;
                    }
                  }
                }
              }

              & > div {
                height: 100%;
                border-right: 1px solid
                  $big-blue-ref-neutral-variant-neutral-variant-95;
                display: flex;
                align-items: center;
                padding-left: 8px;
              }

              & > .dataset-name {
                width: 213px;
                height: 100%;
                border-right: 1px solid
                  $big-blue-ref-neutral-variant-neutral-variant-95;
                cursor: pointer;
                position: relative;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                overflow: visible;
                padding: 11px 0px;
                padding-right: 0;
                margin-right: 0;
              }

              & > .dataset-format {
                width: 107px;
                text-transform: uppercase;
              }

              & > .organisation {
                width: 144px;
                overflow-wrap: break-word;
                word-break: break-all;
              }

              & > .creation-date {
                width: 126px;
              }

              & > .last-modified-date {
                width: 137px;
              }

              & > .dateset-size {
                width: 82px;
              }

              & > .dataset-creator {
                width: 121px;
                padding: 11px 0px 11px 8px;
                cursor: pointer;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: inline-block;
              }
            }
          }
        }
      }
      & > .dataset-table::-webkit-scrollbar {
        display: none;
      }

      & > .right-panel {
        & > .dataset-info {
          width: 316px;
          margin-left: 8px;
          position: relative;
          overflow-y: scroll;
          height: 100vh;
          scrollbar-width: none;

          & > .bd-sh,
          & > .dataset-desc > .bd-sh {
            border-radius: 8px;
            background: $big-blue-sys-light-on-primary;
            box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
              0px 1px 2px 0px rgba(0, 0, 0, 0.3);
          }

          & > .actions {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            height: 48px;
            text-align: center;
            font-size: 14px;
            font-family: SpaceGrotesk;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.1px;
            margin: 2px;

            & > .ac {
              cursor: pointer;
              flex: 1 1 0;
              display: flex;
              justify-content: center;
              height: 48px;
              align-items: center;
              transition: all linear 150ms;

              &:hover {
                background: #eeedf1;
              }
            }

            & > .active {
              color: $big-blue-sys-light-primary;
              position: relative;
              display: flex;
              height: 100%;
              align-items: center;

              &::after {
                content: "";
                width: 63px;
                height: 2px;
                background-color: #00639a;
                position: absolute;
                bottom: 0;
              }
            }
          }

          & > .dataset-desc {
            margin: 8px 2px 2px 2px;

            & > .name-desc {
              display: flex;
              width: 100%;
              padding: 16px;
              flex-direction: column;
              align-items: flex-start;
              margin-top: 8px;

              & > .heading {
                color: $big-blue-sys-light-on-surface;
                font-size: 11px;
                font-family: SpaceGrotesk;
                font-weight: 500;
                line-height: 16px;
                text-transform: uppercase;
                display: flex;
                width: 100%;
                justify-content: space-between;

                & > span {
                  color: $big-blue-sys-light-surface-tint;
                  cursor: pointer;
                }
              }

              & > .name {
                overflow: hidden;
                color: $big-blue-sys-light-on-surface;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 16px;
                font-family: SpaceGrotesk;
                line-height: 24px;
                text-transform: capitalize;
                width: 100%;
              }

              & > .show-desc {
                color: $big-blue-sys-light-secondary;
                font-size: 12px;
                font-family: SpaceGrotesk;
                font-weight: 500;
                line-height: 16px;
                margin-top: 16px;
                text-transform: capitalize;
                cursor: pointer;
              }

              & .description {
                font-size: 12px;
                word-break: break-word;
              }

              & > .open-detail-view {
                text-transform: capitalize;
                color: #00639a;
                cursor: pointer;
                text-align: center;
                font-size: 14px;
                font-family: SpaceGrotesk;
                font-weight: 500;
                line-height: 20px;
                display: flex;
                align-items: center;
              }
            }

            & > .dataset-actions {
              & > .heading {
                display: flex;
                justify-content: space-between;

                & > img {
                  cursor: pointer;
                }
              }

              & .actions {
                display: flex;
                justify-content: space-around;

                & > img {
                  cursor: pointer;
                }
              }
            }

            & > .dataset-actions,
            & > .org,
            & > .col-details {
              display: flex;
              width: 100%;
              padding: 14px 16px;
              flex-direction: column;
              margin-top: 8px;

              & > .heading {
                color: $big-blue-sys-light-on-surface;
                font-size: 11px;
                font-family: "Space Mono";
                font-weight: 500;
                line-height: 16px;
                text-transform: uppercase;
              }
            }

            & > .org {
              overflow: hidden;

              & > .name {
                color: $big-blue-sys-light-on-surface;
                font-size: 16px;
                font-family: "Space Mono";
                line-height: 24px;
                text-transform: capitalize;
              }
            }

            & > .owner-cr-date {
              margin-top: 8px;
              display: flex;

              & > .owner-name,
              & > .cr-date {
                width: 208px;
                height: 64px;
                padding: 16px 15px 16px 16px;
                border-radius: 8px;
                background: $big-blue-sys-light-on-primary;
                box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
                  0px 1px 2px 0px rgba(0, 0, 0, 0.3);

                & > .heading {
                  color: $big-blue-sys-light-on-surface;
                  font-size: 11px;
                  font-family: SpaceGrotesk;
                  font-weight: 500;
                  line-height: 16px;
                  text-transform: uppercase;
                }

                & > .name {
                  color: $big-blue-sys-light-on-surface;
                  font-size: 22px;
                  font-family: SpaceGrotesk;
                  line-height: 28px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              & > .cr-date {
                width: 100px;
                margin-left: 8px;

                & > .name {
                  font-size: 14px;
                }
              }
            }

            & > .col-details {
              & > .heading {
                display: flex;
                justify-content: space-between;

                & .preview {
                  width: 20px;
                  margin-right: 8px;
                }

                & img {
                  cursor: pointer;
                }
              }

              & > ul {
                max-height: 300px;
                overflow-x: auto;

                & > .col-header {
                  color: $big-blue-sys-light-on-surface;
                  font-size: 11px;
                  font-family: SpaceGrotesk;
                  font-weight: 500;
                  line-height: 16px;
                  text-transform: uppercase;
                  margin: 15px 0 8px;

                  & > .col-actions {
                    cursor: pointer;
                    display: flex;
                    gap: 4px;

                    & > span.active {
                      color: $big-blue-sys-light-on-primary-fixed-variant;
                    }
                  }
                }

                & > li {
                  display: flex;
                  justify-content: space-between;

                  & > .col-name {
                    flex: 2;
                    overflow: hidden;
                    color: $big-blue-sys-light-on-surface;
                    text-overflow: ellipsis;
                    whitespace: nowrap;
                    font-size: 11px;
                    font-family: "Space Mono";
                    line-height: 20px;
                  }

                  & > .col-type {
                    flex: 1;
                    color: $big-blue-sys-light-secondary;
                    text-align: right;
                    font-size: 11px;
                    font-family: "Space Mono";
                    line-height: 20px;
                    letter-spacing: 1.5px;
                    text-transform: uppercase;
                  }
                }
              }
            }
          }
        }
      }

      & > .dataset-info::-webkit-scrollbar {
        display: none;
      }
    }

    @media (min-width: 1024px) and (max-width: 1440px) {
      & > .section-1 {
        & > .heading {
          width: 16%;
        }
        & > .custom-dropdown {
          margin-left: 77px;
        }
        & > .seach-box {
          width: 35%;
          margin-left: -55px;
          margin-right: -120px;
        }
      }
      & > .section-2 {
        & > .dataset-table {
          min-width: 63%;
          max-width: 76%;
        }
      }
    }

    @media (min-width: 1280px) {
      & > .section-1 {
        & > .all-filters {
          flex-direction: row;
          & > .filter-selection-btn {
            margin-top: -4px;
          }
          & > .seach-box {
            margin-right: 7px;
            margin-left: -148px;
          }
        }
      }
    }

    @media (min-width: 1440px) {
      & > .section-1 {
        & > .seach-box {
          width: 577px;
        }
      }
      & > .section-2 {
        & > .dataset-table {
          width: 82%;

          & > .table-header {
            & > .dataset-name {
              width: 18%;
            }

            & > .dataset-format {
              width: 13%;
            }

            & > .creation-date {
              width: 15%;
            }

            & > .last-modified-date {
              width: 15%;
            }

            & > .dateset-size {
              width: 8%;
            }

            & > .dataset-creator {
              width: 15%;
            }

            & > .organisation {
              width: 14%;
            }
          }

          & > .table-data {
            ul {
              li {
                & > .dataset-name {
                  width: 18%;
                }

                & > .dataset-format {
                  width: 13%;
                }

                & > .creation-date {
                  width: 15%;
                }

                & > .last-modified-date {
                  width: 15%;
                }

                & > .dateset-size {
                  width: 8%;
                }

                & > .dataset-creator {
                  width: 15%;
                }

                & > .organisation {
                  width: 14%;
                }
              }
            }
          }
        }
      }
    }

    & > section.add-dataset {
      border-radius: 20px;
      background: $big-blue-sys-light-on-primary;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
        0px 1px 2px 0px rgba(0, 0, 0, 0.3);
      margin-top: 8px;
      padding: 50px 75px;

      & > .section-title {
        margin-bottom: 5px;
      }

      & .select-content-box {
        display: flex;
        flex-direction: column;
        padding: 70px 30px;
        border: 1px dashed #000000;
        border-radius: 5px;
        align-items: center;
        background: $big-blue-sys-light-surface-bright;
        gap: 4px;

        & > .selected-content {
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 2px;

          & > .select-file-actions {
            display: flex;
            column-gap: 10px;
            justify-content: center;
          }

          & > .cancel-upload {
            display: flex;
            justify-content: center;
          }
        }

        & > .hg-button {
          margin-top: 10px;
        }
      }

      & .keywords {
        margin-bottom: 20px;
      }

      & .description {
        margin-bottom: 40px;
      }

      & .keywords-list {
        border: 1px solid #000000;
        border-radius: 5px;
      }

      & .form-actions {
        display: flex;
        justify-content: center;
      }
    }

    & > .section-3 {
      display: flex;
      flex-direction: row;
      font-family: SpaceGrotesk;
      align-items: center;
      justify-content: end;
      position: relative;

      & > .back-to-main {
        background-color: black;
        color: white;
        width: 9%;
        border: 1px solid black;
        border-radius: 23px;
        padding: 6px 12px;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        font-family: SpaceGrotesk;
      }
      & > .back-to-main:hover {
        background-color: #303030;
      }
    }

    & > .section-4 {
      display: flex;
      flex-direction: column;

      & > .name-desc {
        display: flex;
        width: 70%;
        flex-direction: row;
        align-items: flex-start;
        height: 40px;

        & > .dataset-name {
          width: 40%;
          display: flex;
          padding: 14px 16px;
          flex-direction: row;
          align-items: flex-start;
          gap: 35px;
          background-color: #ffffff;
          height: 40px;
          margin-top: -7px;
          border: 1px white;
          border-radius: 8px;

          & > .heading {
            color: $big-blue;
            font-size: 11px;
            font-family: SpaceGrotesk;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }

          & > .name {
            color: $big-blue-sys-light-on-surface;
            text-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
            font-size: 22px;
            font-family: SpaceGrotesk;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            display: flex;
            gap: 59px;
            margin-top: -1px;
            background-color: #ffffff;
            border: 1px solid white;
            border-radius: 8px;
            padding: 8px 19px;
            height: 40px;
          }
        }

        & > .version-box {
          background-color: #ffffff;
          border: 1px solid white;
          border-radius: 8px;
          display: flex;
          gap: 10px;
          height: 40px;
          overflow: hidden;
          padding: 14px 16px;
          width: 191px;
          margin-top: -7px;
          cursor: pointer;
          margin-left: 25px;

          & > .version-text {
            color: #1a1c1e;
            font-size: 16px;
            font-family: "Space Mono";
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            display: inline-flex;
            align-items: flex-start;
            flex: 0 0 auto;
            flex-direction: column;
            justify-content: center;
          }
          & > .close {
            cursor: pointer;
            height: 24px;
            margin-left: 35px;
            margin-top: -5px;
          }

          & > .dropdown-menu {
            position: absolute;
            background: #fff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            margin-top: 26px;
            z-index: 1000;
            width: 150px;
            display: block;

            & > .dropdown-item {
              padding: 8px 12px;
              cursor: pointer;
              border-bottom: 1px solid #f0f0f0;
            }
          }
        }

        & > .manage-version {
          border: 1px solid;
          border-radius: 30px;
          border-color: #82a6d9;
          opacity: 1;
          color: #82a6d9;
          padding: 6px 15px;
          height: 36px;
          font-size: 14px;
          margin-top: -5px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          font-family: Roboto;
          margin-left: 30px;
          text-transform: none;
        }
        & > .manage-version:hover {
          background-color: #ecf4ff;
        }
      }
    }

    & > .section-4-1 {
      display: flex;
      margin-top: 16px;

      & .sh-dd {
        display: flex;
        width: 316px;
        height: 72px;
        padding: 14px 10px;
        flex-direction: column;
        border-radius: 8px;
        background: $big-blue-sys-light-on-primary;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
          0px 1px 2px 0px rgba(0, 0, 0, 0.3);
        min-height: 72px;

        & > .heading {
          color: $big-blue-sys-light-on-surface;
          font-size: 11px;
          font-family: SpaceGrotesk;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          text-transform: uppercase;
          display: flex;
          justify-content: space-between;
        }

        & > .attribution {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          // height: 100px;
          width: 316px;

          & > .attribution-item {
            color: #51606f;
            font-family: "Space Mono";
            font-size: 11px;
            font-weight: 400;
            line-height: 20px;
            // text-align: center;
            border: 1px solid #f8f9fa;
            border-radius: 30px;
            background: #dde8f4;
            padding: 0px 10px;
            // margin-top: 3px;
            // height: 30px;
            word-break: break-all;
            display: flex;
            align-items: center;
            height: 34px;
          }

          & > .view-more-wrapper {
            position: relative;

            & > .view-more-button {
              background: #fef7ff;
              color: #51606f;
              cursor: pointer;
              font-size: 11px;
              border: 5px;
              border-radius: 30px;
              margin-left: 45px;
              padding: 4px 12px;
              font-family: Space Mono;
              margin-top: 0px;
            }

            & > .hover-modal {
              position: fixed;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: white;
              border: 2px solid #ddd;
              border-radius: 8px;
              box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
              padding: 16px;
              z-index: 1000;
              width: 80%; /* Adjust based on your preference */
              max-height: 70%; /* To avoid overflow */
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              // flex-wrap: wrap; /* Makes tags wrap */
              // gap: 10px; /* Adds spacing between tags */

              & > .attribution-heading {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 10px;
              }

              & > .attribution-container {
                gap: 12px 16px;

                & > .modal-attribution-item {
                  display: inline-block;
                  color: #51606f;
                  font-family: "Space Mono";
                  font-size: 11px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: center;
                  border: 1px solid #f8f9fa;
                  border-radius: 30px;
                  background: #dde8f4;
                  padding: 4px 15px;
                  margin-top: 3px;
                  overflow: hidden;
                }
              }
            }
          }
        }

        & > .heading {
          color: $big-blue-sys-light-on-surface;
          font-size: 11px;
          font-family: SpaceGrotesk;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          text-transform: uppercase;
        }

        & > .heading123 {
          color: $big-blue-sys-light-on-surface;
          font-size: 11px;
          font-family: SpaceGrotesk;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          text-transform: uppercase;
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-size: 12px;
        }

        & > .unique {
          display: flex;
          flex-direction: column;
          margin-top: 4%;

          & > .heading-unique {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }

          & > .heading-unique2 {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 3%;
          }
        }

        & > .name {
          color: #1a1c1e;
          font-size: 16px;
          font-family: Space Mono;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          // text-transform: uppercase;
          margin-bottom: -43px;
        }
        & > .manage-license {
          display: flex;
          justify-content: center;
          border: 1px solid;
          border-radius: 30px;
          border-color: #82a6d9;
          opacity: 1;
          color: #82a6d9;
          margin-left: 79%;
          width: 20%;
          margin-top: 7px;
          padding: 8px 9px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          font-family: Roboto;
          background-color: white;
        }
        & > .manage-license:hover {
          background-color: #ecf4ff;
        }
        & > .manage-license22 {
          display: flex;
          justify-content: end;
          border: 1px solid;
          border-radius: 30px;
          border-color: #82a6d9;
          opacity: 1;
          color: #82a6d9;
          margin-left: 94px;
          // width:23%;
          margin-top: 5px;
          // padding: 8px 13px;
          height: 36px;
          // width: 192px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          font-family: Roboto;

          font-weight: 500;
          font-family: Roboto;
          // margin-top: 4px;
          padding: 7px 18px;

          &:hover {
            cursor: pointer;
          }
        }
        & > .manage-license22:hover {
          background-color: #ecf4ff;
        }
      }

      & > .section-4 {
        display: flex;
        flex-direction: column;
        width: 100%;

        & > .general-info {
          display: flex;
          gap: 8px;

          & > .name-desc {
            display: flex;
            width: 632px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1;

            & > .sh-dd {
              width: 100%;
              min-height: 72px;

              & > .tags {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
                margin-top: 4px;

                & > .tag {
                  text-transform: capitalize;
                  display: flex;
                  height: 25px;
                  padding: 6px 16px;
                  justify-content: center;
                  align-items: center;
                  color: #42474e;
                  background-color: transparent;
                  text-align: center;
                  font-size: 12px;
                  font-family: SpaceGrotesk;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  text-transform: capitalize;
                  background: #e8e8e8;
                  border-radius: 15px;
                }
              }
            }

            & > .dataset-name {
              width: 100%;
              display: flex;
              padding: 14px 16px;
              flex-direction: column;
              align-items: flex-start;

              & > .heading {
                color: $big-blue;
                font-size: 11px;
                font-family: SpaceGrotesk;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
              }

              & > .name {
                color: $big-blue-sys-light-on-surface;
                text-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
                font-size: 22px;
                font-family: SpaceGrotesk;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;

                & > .version {
                  color: #00639a;
                  font-size: 16px;
                  font-family: "Space Mono";
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                  cursor: pointer;
                }
              }
            }

            & > .description {
              display: flex;
              width: 100%;
              height: 312px;
              padding: 16px;
              flex-direction: column;
              align-items: flex-start;
              border-radius: 8px;
              background: $big-blue-sys-light-on-primary;
              box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
                0px 1px 2px 0px rgba(0, 0, 0, 0.3);
              margin-top: 8px;
              position: relative;

              & > .heading {
                color: #1a1c1e;
                text-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
                font-size: 12px;
                font-family: SpaceGrotesk;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                opacity: 0.8;
                display: flex;
                justify-content: space-between;
                width: 100%;
              }

              & > .content {
                color: #1a1c1e;
                text-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
                font-size: 14px;
                font-family: "Space Mono";
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                overflow: hidden;
                word-break: break-word;
                width: 85%;
                height: 183px;
                margin-top: 1%;

                & > .show-more {
                  color: #82a6d9;
                  font-size: 14px;
                  font-family: "Space Mono";
                  font-weight: 400;
                  line-height: 20px;
                  // text-transform: capitalize;
                  cursor: pointer;
                  position: absolute;

                  bottom: 70px;
                }
              }
            }

            & > .tags {
              display: flex;
              width: 100%;
              height: 152px;
              padding: 16px;
              flex-direction: column;
              align-items: flex-start;
              border-radius: 8px;
              background: $big-blue-sys-light-on-primary;
              box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
                0px 1px 2px 0px rgba(0, 0, 0, 0.3);
              // margin-top: 8px;
              position: relative;

              & > .heading {
                color: #1a1c1e;
                text-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
                font-size: 12px;
                font-family: SpaceGrotesk;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                opacity: 0.8;
                display: flex;
                justify-content: space-between;
                width: 100%;
              }

              & > .tagging {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
                // height: 100px;
                width: 696px;

                // overflow: hidden;
                // max-height: 100px;
                // position: relative;

                & > .tagged {
                  color: #51606f;
                  font-family: "Space Mono";
                  font-size: 11px;
                  font-weight: 400;
                  line-height: 20px;
                  // text-align: center;
                  border: 1px solid #f8f9fa;
                  border-radius: 30px;
                  background: #dde8f4;
                  padding: 0px 10px;
                  margin-top: 3px;
                  // height: 30px;
                  word-break: break-all;
                  display: flex;
                  align-items: center;
                  height: 34px;
                }

                & > .view-more-wrapper {
                  position: relative;

                  & > .view-more-button {
                    background: #fef7ff;
                    color: #51606f;
                    cursor: pointer;
                    font-size: 11px;
                    border: 5px;
                    border-radius: 30px;
                    margin-left: 45px;
                    padding: 4px 12px;
                    font-family: Space Mono;
                    margin-top: 0px;
                  }

                  & > .hover-modal {
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: white;
                    border: 2px solid #ddd;
                    border-radius: 8px;
                    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
                    padding: 16px;
                    z-index: 1000;
                    width: 80%; /* Adjust based on your preference */
                    max-height: 70%; /* To avoid overflow */
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    // flex-wrap: wrap; /* Makes tags wrap */
                    // gap: 10px; /* Adds spacing between tags */

                    & > .tag-heading {
                      font-size: 16px;
                      font-weight: bold;
                      margin-bottom: 10px;
                    }

                    & > .tag-container {
                      gap: 12px 16px;

                      & > .modal-tagged {
                        display: inline-block;
                        color: #51606f;
                        font-family: "Space Mono";
                        font-size: 11px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                        border: 1px solid #f8f9fa;
                        border-radius: 30px;
                        background: #dde8f4;
                        padding: 4px 15px;
                        margin-top: 3px;
                        overflow: hidden;
                      }
                    }
                  }
                }
              }

              & > .content {
                color: #1a1c1e;
                text-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
                font-size: 14px;
                font-family: "Space Mono";
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                overflow: hidden;
                word-break: break-word;
                width: 85%;
                height: 140px;
                margin-top: 1%;

                & > .show-more {
                  color: #82a6d9;
                  font-size: 14px;
                  font-family: "Space Mono";
                  font-weight: 400;
                  line-height: 20px;
                  // text-transform: capitalize;
                  cursor: pointer;
                  position: absolute;

                  bottom: 13px;
                }
              }
            }
          }

          & > .dataset-access {
            gap: 8px;
            display: flex;
            flex-direction: column;

            & > .actions {
              & > .handlers {
                display: flex;
                justify-content: space-evenly;

                & img {
                  cursor: pointer;
                  width: 43px;
                }
              }
            }

            & > .dataset-users {
              & > .heading {
                display: flex;
                justify-content: space-between;
                text-transform: capitalize;
                margin-bottom: 8px;

                & > span {
                  color: $big-blue-sys-light-on-surface;
                  text-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
                  font-size: 12px;
                  font-family: SpaceGrotesk;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px;
                }
              }

              & > .users-list {
                & > ul {
                  & > li {
                    display: flex;
                    justify-content: space-between;

                    & > .name {
                      color: $big-blue-sys-light-on-surface;
                      text-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
                      font-size: 14px;
                      font-family: "Space Mono";
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px;
                      text-transform: capitalize;
                    }

                    & > .access-type {
                      color: $big-blue-sys-light-on-surface;
                      text-align: right;
                      text-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
                      font-size: 14px;
                      font-family: "Space Mono";
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px;
                      text-transform: capitalize;
                    }
                  }
                }
              }
            }
          }

          & > .owner {
            margin-left: 8px;

            & > .org-cr {
              margin-top: 8px;
              display: flex;
              height: 72px;

              & > .org {
                width: 100%;

                & > .name {
                  color: $big-blue-sys-light-on-surface;
                  font-size: 16px;
                  font-family: "Space Mono";
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                }
              }

              & > .cr-date {
                margin-left: 8px;
                width: 30%;

                & > .name {
                  color: $big-blue-sys-light-on-surface;
                  font-size: 16px;
                  font-family: SpaceGrotesk;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;
                }
              }
            }

            & > .ver-up {
              margin-top: 8px;
              display: flex;
              height: 72px;

              & > .version {
                width: 70%;

                & > .heading {
                  color: $big-blue-sys-light-primary;
                }

                & > .name {
                  color: $big-blue-sys-light-primary;
                  font-size: 16px;
                  font-family: "Space Mono";
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                }
              }

              & > .up-date {
                margin-left: 8px;
                width: 30%;

                & > .name {
                  color: $big-blue-sys-light-on-surface;
                  font-size: 16px;
                  font-family: SpaceGrotesk;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;
                }
              }
            }
          }

          & > .dataset-info {
            width: 316px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            & > .size-rw-col {
              display: flex;
              column-gap: 8px;

              & > .sh-dd {
                width: auto;
                flex: 1 1 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                & > .name {
                  font-size: 16px;
                }
              }
            }

            & > .actions {
              margin-top: 8px;
              padding: 16px 0px 48px 0px;

              & > .heading {
                text-align: center;
                margin-bottom: 24px;
              }

              & > .handlers {
                display: flex;
                justify-content: space-around;

                & > img {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      & > .section-6 {
        & .sh-dd {
          & > .name {
            font-size: 16px;

            &.tags {
              display: flex;
              flex-wrap: wrap;
              gap: 8px;
              margin-top: 8px;

              & > .tag {
                text-transform: capitalize;
                display: flex;
                height: 32px;
                padding: 6px 16px;
                justify-content: center;
                align-items: center;
                color: #42474e;
                background-color: transparent;
                text-align: center;
                font-size: 14px;
                font-family: SpaceGrotesk;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                text-transform: capitalize;
                background: #e8e8e8;
                border-radius: 15px;
              }
            }
          }
        }

        & > .dataset-access {
          width: 316px;
          margin-left: 8px;
          display: flex;
          flex-direction: column;
          gap: 8px;

          & > .actions {
            & > .handlers {
              display: flex;
              justify-content: space-between;

              & img {
                cursor: pointer;
                width: 43px;
              }
            }
          }

          & > .grant-access {
            flex-direction: row;
            justify-content: space-between;
            padding: 24px 16px;

            & > .heading {
              color: $big-blue-sys-light-on-surface;
              text-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
              font-size: 16px;
              font-family: "Space Mono";
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
          }

          & > .dataset-users {
            & > .heading {
              display: flex;
              justify-content: space-between;
              text-transform: capitalize;
              margin-bottom: 8px;

              & > span {
                color: $big-blue-sys-light-on-surface;
                text-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
                font-size: 12px;
                font-family: SpaceGrotesk;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
              }
            }

            & > .users-list {
              & > ul {
                & > li {
                  display: flex;
                  justify-content: space-between;

                  & > .name {
                    color: $big-blue-sys-light-on-surface;
                    text-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
                    font-size: 14px;
                    font-family: "Space Mono";
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    text-transform: capitalize;
                  }

                  & > .access-type {
                    color: $big-blue-sys-light-on-surface;
                    text-align: right;
                    text-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
                    font-size: 14px;
                    font-family: "Space Mono";
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    text-transform: capitalize;
                  }
                }
              }
            }
          }

          & .col-details {
            & > .heading {
              display: flex;
              justify-content: space-between;

              & .preview {
                width: 20px;
                margin-right: 8px;
              }

              & img {
                cursor: pointer;
              }
            }

            & > ul {
              max-height: 51vh;
              overflow-x: auto;

              & > .col-header {
                color: $big-blue-sys-light-on-surface;
                font-size: 11px;
                font-family: SpaceGrotesk;
                font-weight: 500;
                line-height: 16px;
                text-transform: uppercase;
                margin: 15px 0 8px;
              }

              & > li {
                display: flex;
                justify-content: space-between;

                & > .col-name {
                  overflow: hidden;
                  color: $big-blue-sys-light-on-surface;
                  text-overflow: ellipsis;
                  whitespace: nowrap;
                  font-size: 11px;
                  font-family: "Space Mono";
                  line-height: 20px;
                }

                & > .col-type {
                  color: $big-blue-sys-light-secondary;
                  text-align: right;
                  font-size: 11px;
                  font-family: "Space Mono";
                  line-height: 20px;
                  letter-spacing: 1.5px;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }

    .section-5 {
      margin-top: 8px;

      & > .ds-name {
        color: $big-blue-ref-neutral-neutral-30;
        font-size: 11px;
        font-family: SpaceGrotesk;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        gap: 8px;

        & > img {
          cursor: pointer;
        }
      }

      & > .table-container {
        max-width: 100%;
        overflow: auto;
        max-height: 83vh;
      }
    }
  }
}
