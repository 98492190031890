.published-actions-component {
  margin-bottom: 24px;

  & > .published-actions {
    display: flex;

    & > .published {
      width: 128px;
      height: 68px;
      padding: 20px 12px 20px 12px;
      gap: 10px;
      border-radius: 20px;
      border: 1px solid rgba(141, 226, 190, 1);
      font-family: SpaceGrotesk;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: rgba(0, 0, 0, 1);
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);

      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);

      &.draft-border {
        border: 1px solid rgba(130, 130, 130, 1);
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
      }
    }

    & > .actions {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 68px;
      padding: 16px 24px;
      margin-left: 11px;
      gap: 10px;
      border-radius: 20px;
      border: 1px solid rgba(130, 166, 217, 1);

      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);

      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);

      & > .action {
        width: 94px;
        height: 24px;
        padding-top: 4px;
        color: rgba(0, 0, 0, 1);
        font-family: SpaceGrotesk;
        font-weight: 500;
        font-size: 16px;
      }

      & > .all-actions {
        & > .action-btn,
        .publish-btn {
          height: 36px;
          padding: 4px 18px 4px 18px;
          margin: 0 6px;
          gap: 10px;
          border-radius: 30px;
          border: 1px solid rgba(130, 166, 217, 1);
          background-color: transparent;
          color: rgba(130, 166, 217, 1);

          &.active {
            background: rgba(130, 166, 217, 1);
            color: #fff;
          }

          &:hover {
            background: rgba(130, 166, 217, 1);
            color: #fff;
          }
        }

        & > .publish-btn {
          padding: 4px 46px;
        }
      }
    }
  }
}

.modal {
  & > .modal-dialog.unpublished-modal {
    & > .modal-content {
      border-radius: 25px;
      margin: auto;
      padding: 10px 20px 0px 20px;
      max-width: 446px;
      & > .modal-body {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #42474e;
      }
      & > .modal-footer {
        display: block;
        & > .unpublish-checkbox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 8px;
        }
        & > .unpublish-actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 0px;

          & > .upublish-cancle {
            padding: 10px 0px;
            border: none;
            background: transparent;
            color: #00639a;
          }
          & > .upublish-status {
            padding: 10px 15px;
            border: none;
            background: #00639a;
            color: white;
            border-radius: 120px;
          }
        }
      }
    }
  }
}
