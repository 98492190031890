$big-blue-theme-big-blue: #82A6D9;
$big-blue-theme-medium-grey-prime: #505050;
$big-blue-sys-light-primary-container: #CDE5FF;
$big-blue-sys-light-on-primary-container: #001D32;
$big-blue-ref-neutral-neutral-96: #F3F3F6;
$big-blue-sys-light-on-primary: #FFF;
$big-blue-sys-light-on-surface: #172737;
$big-blue-sys-light-outline: #72777F;
$big-blue-sys-light-primary: #00639A;
$big-blue-sys-light-on-surface-variant: #42474E;
$big-blue-sys-light-surface-tint: #00639A;
$big-blue-sys-light-surface-bright: #F9F9FC;
$big-blue-ref-neutral-variant-neutral-variant-95: #ECF1F9;
$big-blue-sys-light-secondary: #51606F;
$big-blue-sys-light-surface-container-lowest: #FFF;
$big-blue-sys-light-surface-container: #EEEDF1;
$big-blue: #82A6D9;
$big-blue-ref-neutral-neutral-30: #45474A;
$big-blue-sys-light-surface-container-low: #F3F3F6;
$big-blue-sys-light-secondary-container: #D5E4F6;
$m-3-sys-light-on-surface-variant: #49454F;
$big-blue-state-layers-sys-light-on-surface-opacity-008: rgba(29, 27, 32, 0.08); 
$big-blue-state-layers-sys-light-on-surface-opacity-012: rgba(29, 27, 32, 0.12);
$big-blue-ref-neutral-neutral-90: #E2E2E5;
$big-blue-ref-neutral-neutral-98: #F9F9FC;
$big-blue-sys-light-on-primary-fixed-variant: #004A75;