.modal {
    &>.modal-dialog.edit-description-component {
        &>.modal-content {
            width: 800px;

            &>.modal-header {
                padding: 16px 40px;

                &>.modal-title {
                    font-family: SpaceGrotesk;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 32px;
                    text-align: left;
                    color: rgba(0, 29, 50, 1);

                }
            }

            &>.modal-body {
                padding-left: 24px;

                &>.heading,
                .label {
                    margin-top: 16px;
                    font-family: SpaceGrotesk;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    color: rgba(81, 96, 111, 1);
                }

                &>.edit-description,
                .edit-feild,
                .additional-documentation-text-feild {
                    width: 704px;
                    height: 200px;
                    padding: 16px 10px 11px 10px;
                    margin-top: 12px;
                    gap: 10px;
                    border-radius: 8px;
                    background: rgba(252, 252, 255, 1);
                    border: 2px dotted rgba(81, 96, 111, 1);
                    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
                }

                &>.edit-feild {
                    height: 43px;
                }

                &>.additional-documentation-text-feild {
                    height: 116px;
                }
            }

            &>.modal-footer {
                margin-right: 30px;
                margin-top: 16px;
            }
        }
    }
}