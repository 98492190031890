.grid-dataset-container {
  max-height: 200px;
  gap: 6px;

  & > .dataset-details {
    display: flex;

    & > .dataset-img {
      width: 60px;
      height: 60px;
      border-radius: 3px;
      //background: radial-gradient(116.71% 85% at 50% 50%, #80ABD9 0%, #CDD4E6 95.68%);
    }

    & > .dataset-info {
      height: 40px;
      gap: 4px;
      margin-left: 20px;

      & > .dataset-title {
        min-height: 20px;
        font-family: spaceGrotesk;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: rgba(0, 0, 0, 1);
      }

      & > .organisation {
        height: 16px;
        font-family: monospace;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: rgba(114, 119, 127, 1);
      }
    }
  }

  & > .dataset-description {
    max-height: 40px;
    font-family: monospace;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
  }

  & > .data-type {
    height: 15px;
    margin-top: 6px;
    font-family: monospace;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: rgba(144, 144, 144, 1);
  }

  & > .license {
    height: 16px;
    color: rgba(144, 144, 144, 1);
    font-family: monospace;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 10px;
    text-align: left;
  }

  & > .btn {
    width: 83px;
    height: 30px;
    border-radius: 40px;
    background: rgba(213, 228, 246, 1);
    font-family: monospace;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
  }
}
