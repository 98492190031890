.filter-container {
  width: 560px;
  max-height: 600px;
  scrollbar-width: none;
  overflow: scroll;
  padding: 20px;
  background-color: #ffffff;
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 8px 3px #00000026;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  transition: all;
  border-radius: 10px;
  & > .filter-header {
    display: flex;
    justify-content: space-between;
    & > .cross:hover {
      cursor: pointer;
    }
  }

  & > .filter-section {
    & > .filter-type {
      margin-top: 30px;
      & > div {
        & > .filter-tag {
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          padding: 10px;
          min-width: 98px;
          height: 32px;
          border-radius: 8px;
          border: 1px solid #cac4d0;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    & > div {
      & > .filter-selection {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 149px;
        height: 36px;
        border-radius: 20px;
        border: 1px solid #000000;
        padding: 10px 12px;
        margin: 30px 0px;
        background: transparent;
      }
      & > .filter-selection:hover {
        background-color: #f1f1f1;
      }
    }
  }
}
