@import '../App.scss';
@import 'variables';

.modal {

    & > .modal-dialog.view-dataset-website-edit-component {



        & > .modal-content {
            // background: $big-blue-ref-neutral-neutral-96;
            border-radius: 20px;
            background: #FFF;
            box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
            width: 138%;
            margin-left: -25%;

            & > .modal-header {
                border: none;
                margin-left: -4%;
                
                
                
                & > .modal-title {
                    color: #001D32;
                    font-size: 24px;
                    font-family: SpaceGrotesk;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 32px;
                    letter-spacing: 0.1px;
                    margin-bottom: -15px;
                    margin-top: 12px;
                    margin-left: 42px;

                }

                & > .btn-close {

                }
            } 

            & > .modal-body {
                padding: 0;
                margin: 0 1rem;
                border-radius: 8px;
                width: 677px;
                // background: $big-blue-sys-light-on-primary;

                & > .content {
                width: 65%;
                height: 8%;
                margin-top: 31px;
                margin-left: 27px;

                & > .content-2 {

                    & > .label-text-2 {
                        color: #42474E;
                        font-family: 'Space Mono';
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        width: 220px;
        
                        }

                        & > .div-wrapper {
                            & > .input-text-2 {
                                color: #1A1C1E;
                                font-family: 'Space Mono';
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 24px;
                
                                }
                            }

                        }
    
                

                    & > .label-text-3 {
                        color: #51606F;
                        font-family: 'SpaceGrotesk';
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        }

                    

                }


                & > .div-wrapper100 {
                    margin-top: 35px;
                    margin-left: 27px;

                    & > .label-text-201{
                        color: #51606f;
                        font-family: 'SpaceGrotesk';
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
    
                    }
                }


                & > ul.versions-list {

                    & > li {
                        display: flex;
                        padding: 4px 24px 4px 16px;
                        align-items: center;
                        gap: 16px;
                        flex: 1 0 0;
                        align-self: stretch;
                        color: $big-blue-sys-light-on-surface;
                        font-size: 14px;
                        font-family: 'Space Mono';
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        height: 40px;
                        position: relative;
                        cursor: pointer;

                        &::after {
                            content: '';
                            background-image: url('../images/checkbox-blue.svg');
                            background-size: 38px;
                            width: 38px;
                            height: 38px;
                            position: absolute;
                            right: 12px;
                        }

                        &.active {
                            background: rgba(29, 27, 32, 0.08);

                            &::after {
                                background-image: url('../images/checkbox-blue-checked.svg');
                            }
                        }
                    }
                    

                }

            }

            & > .modal-footer {
                margin: 0;
                margin-left: -2%;
                width: 100%;

                & > .togglebtn {
                    display: flex;
                    justify-content: space-between;
                    width: 94%;
                    margin-top: 65px;
                    margin-bottom: 22px;
                
            & > .btnspecial {
                font-family: SpaceGrotesk;
                border-radius: 100px;
                background: #00639A;
                color: #fff;
                width: 84px;
                padding: 8px 12px;
                margin-right:89px;
            
            }

        }

                & > .hg-button {
                    
                    & > .btn {
                        padding: 10px 24px 10px 24px;
                        display: flex;
                        height: 32px;
                        padding: 6px 16px;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        color: $big-blue-sys-light-on-surface-variant;
                        background-color: transparent;
                        text-align: center;
                        font-size: 14px;
                        font-family: SpaceGrotesk;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        text-transform: capitalize;
                        border: none;

                        &.btn-secondary {
                            background: transparent;
                            width:60px
                        }

                        &.btn-primary {
                            border-radius: 100px;
                            background: $big-blue-sys-light-primary;
                            color: #fff;
                            padding: 20px 24px 20px 24px;
                        }
                    }
                }
            }
        }
    
}
}