@import '../App.scss';
@import 'variables';

.menu-icon {
    top: 160px;
    position: fixed;
    left: 17px;
    cursor: pointer;
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #00000021;
    z-index: 30;
    transition: all linear 200ms;
}

.hg-sidebar {
    width: 339px;
    height: 100vh;
    flex: 0 0 300px;
    position: fixed;
    // opacity: 0.9900000095367432;
    background: $big-blue-sys-light-surface-container-low;
    top: 0;
    left: 0;
    z-index: 31;
    padding: 0 16px;
    max-height: 100vh;
    overflow-y: auto;
    transition: all linear 350ms;

    &.close {
        transform: translateX(-339px);
    }

    & > .logo {
        padding: 30px 0 0 0px;
        text-align: center;

        & > img {
            width: 80px;
        }
    }

    & > .heading {
        margin-top: 55px;
        margin-left: 16px;
        color: $big-blue-sys-light-on-surface-variant;
        font-size: 14px;
        font-family: SpaceGrotesk;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-transform: capitalize;
    }

    .menu {
        margin-top: 46px;

        & > .menu-heading {
            padding: 18px 8px 18px 16px;
            height: 56px;
            color: $big-blue-sys-light-on-surface-variant;
            font-size: 16px;
            font-family: SpaceGrotesk;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            text-transform: capitalize;

            &.all-caps {
                text-transform: uppercase;
            }
        }

        &>ul>li {
            padding: 16px 0;
            cursor: pointer;
            color: $big-blue-sys-light-on-surface-variant;
            font-size: 14px;
            font-family: SpaceGrotesk;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            text-transform: capitalize;
            padding: 16px 0;
            margin: 0 24px;

            &:last-child {
                border-bottom: 1px solid #C2C7CF;
            }

            &.active {
                border-radius: 100px;
                background: $big-blue-sys-light-secondary-container;
                margin: 0;
                padding: 16px 24px;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    
}