.search-component {
    width: 100%;

    & > .search-keywords-list {
        background-color: transparent;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        font-family: 'Space Mono';
        width: 100%;
        min-height: 39px;
        border-radius: 20px;
        border: 1px solid #72777F;
        padding: 8px;
        padding-right: 35px;
        position: relative;

        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            li {
                background: #E8E8E8;
                border-radius: 15px;
                padding: 5px 10px;
                display: flex;
                align-items: center;
                text-transform: lowercase;
                word-break: break-word;
                height: 22px;

                &>img {
                    margin-left: 5px;
                    cursor: pointer;
                }

                &.add-new-keyword {
                    cursor: pointer;

                    img {
                        margin-left: 10px;
                        cursor: pointer;
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }

        .search-icon {
            position: absolute;
            right: 10px;
            bottom: 6px;
            cursor: pointer;
        }

        .tags-input {
            border: none;
            font-family: 'Space Mono';
            height: 22px;
            background: transparent;
        }

        .input-toggler {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
    }

    & > .filters {
        display: flex;
        gap: 8px;
        margin-top: 8px;

        & > .filter {
            display: flex;
            width: 140px;
            height: 32px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 18px;
            border: 1px solid #72777F;
            color: #42474E;
            text-align: center;
            font-size: 14px;
            font-family: SpaceGrotesk;
            font-weight: 500;
            line-height: 20px;
            cursor: pointer;
        }

        & > .dsg-dropdown {

            &.search-type {
                width: 140px;
                flex: 0;

                & > .selected-item {
                    display: flex;
                    width: 140px;
                    height: 32px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    border-radius: 18px;
                    border: 1px solid #72777F;
                    color: #42474E;
                    text-align: center;
                    font-size: 14px;
                    font-family: SpaceGrotesk;
                    font-weight: 500;
                    line-height: 20px;
                    cursor: pointer;
                }

                & > .dropdown-list {
                    z-index: 2;
                    border-top: 1px solid #577692;
                    border: none;
                    padding: 0;

                    & > ul {
                        padding: 0;

                        & > li {
                            border-top: none;
                            padding: 0 24px 0 16px;

                            &:hover {
                                background: #1D1B2014;
                            }
                        }
                    }
                }
            }
        }
    }

    
}

.react-datepicker-popper {
    z-index: 2;
}

.filter {
    display: flex;
    width: 140px;
    height: 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 18px;
    border: 1px solid #72777F;
    color: #42474E;
    text-align: center;
    font-size: 14px;
    font-family: SpaceGrotesk;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
}