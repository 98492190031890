.manage-license-container {
  display: flex;
  width: 800px;
  padding: var(--Spacing-SM, 32px);
  flex-direction: column;
  align-items: center;
  gap: var(--Spacing-SM, 32px);
  border-radius: 20px;
  background: var(--BigBlue-ref-neutral-neutral96, #f3f3f6);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  .manage-license-title-cross-img {
    display: flex;
    justify-content: space-between;
    .manage-license-cross-img {
      background-color: #f3f3f6;
      cursor: pointer;
    }
  }

  .manage-license-title {
    display: flex;
    width: 720px;
    justify-content: space-between;
    align-items: center;
    //styleName: BigBlue/headline/small;
    // font-family: Space Grotesk;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(0, 29, 50, 1);
  }
  .manage-license-dataset-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    .manage-license-dataset-title {
      margin-bottom: 0;
      color: var(--BigBlue-sys-light-on-surface-variant, #42474e);
      font-feature-settings: "liga" off;

      /* BigBlue/body/small */
      // font-family: "Space Mono";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
    .manage-license-dataset-name {
      color: var(--BigBlue-sys-light-on-surface, #1a1c1e);
      font-feature-settings: "liga" off;
      /* BigBlue/body/large */
      // font-family: "Space Mono";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      //styleName: BigBlue/body/large;
      // font-family: Space Mono;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: rgba(26, 28, 30, 1);
    }
  }
  // .manage-license-apply-container {
  //   display: flex;
  //   height: 73px;
  //   flex-direction: column;
  //   align-items: flex-start;
  //   align-self: stretch;
  //   .manage-license-apply-heading {
  //     align-self: stretch;
  //     color: var(
  //       --M3-sys-light-on-surface-variant,
  //       var(--Schemes-On-Surface-Variant, #49454f)
  //     );
  //     /* M3/label/medium */
  //     font-family: Roboto;
  //     font-size: 12px;
  //     font-style: normal;
  //     font-weight: 500;
  //     line-height: 16px; /* 133.333% */
  //     letter-spacing: 0.5px;
  //     //styleName: M3/label/medium;
  //     font-family: Roboto;
  //     font-size: 12px;
  //     font-weight: 500;
  //     line-height: 16px;
  //     letter-spacing: 0.5px;
  //     text-align: left;
  //     text-underline-position: from-font;
  //     text-decoration-skip-ink: none;
  //     color: rgba(73, 69, 79, 1);
  //   }
  //   .manage-license-apply-content {
  //       display: flex;
  //       width: 100%;
  //       justify-content: space-between;
  //       .manage-license-apply-content-heading{
  //           align-self: stretch;
  //           color: var(--BigBlue-sys-light-on-surface, #1a1c1e);
  //           font-feature-settings: "liga" off;
  //           /* BigBlue/body/large */
  //           font-family: "Space Mono";
  //           font-size: 16px;
  //           font-style: normal;
  //           font-weight: 400;
  //           //styleName: BigBlue/body/large;
  //           font-family: Space Mono;
  //           font-size: 16px;
  //           font-weight: 400;
  //           text-align: left;
  //           text-underline-position: from-font;
  //           text-decoration-skip-ink: none;
  //           color: rgba(26, 28, 30, 1);
  //       }
  //       img{
  //           align-self: flex-start;
  //       }
  //   }
  // }
  .switch-container {
    align-self: flex-start;
    p {
      display: inline;
      margin-left: 0.5em;
    }
  }

  .license-type-card-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .dataset-license-title {
      color: var(--BigBlue-sys-light-on-surface-variant, #42474e);
      font-feature-settings: "liga" off;
    }
    .desc {
      font-weight: bold;
    }

    .license-cards {
      display: flex;
      width: 100%;
      gap: 1em;
      justify-content: space-between;
      .blue {
        border: 2px solid rgba(130, 166, 217, 1);
      }
      .license-card {
        display: flex;
        cursor: pointer;
        flex-direction: column;
        width: 48%;
        gap: 0.2em;
        .card-title {
          //styleName: BigBlue/title/medium;

          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
        p {
          color: rgba(0, 0, 0, 1);
          //styleName: BigBlue/body/medium;
          // font-family: Space Mono;
          font-size: 14px;
          font-weight: 400;
          width: 100%;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          height: fit-content;
        }

        justify-content: center;
        align-items: center;
        height: 12em;
        padding: 0.5em;
        border-radius: 8px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
        color: rgba(252, 252, 255, 1);
      }
    }
  }
  .manage-license-help-heading {
    align-self: flex-start;
    font-weight: bold;
  }
  .manage-license-dataset-license-container {
    display: flex;
    height: 73px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    .manage-license-heading {
      margin-bottom: 0;
      color: var(
        --M3-sys-light-on-surface-variant,
        var(--Schemes-On-Surface-Variant, #49454f)
      );

      /* M3/label/medium */
      // font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.5px;
      //styleName: M3/label/medium;
      // font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: rgba(73, 69, 79, 1);
    }
    .manage-license-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .manage-license-content-heading {
        color: var(--BigBlue-sys-light-on-surface, #1a1c1e);
        font-feature-settings: "liga" off;
        /* BigBlue/body/large */
        // font-family: "Space Mono";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        //styleName: BigBlue/body/large;
        // font-family: Space Mono;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(26, 28, 30, 1);
      }
      img {
        align-self: flex-start;
      }
    }
  }
  .manage-license-upload-button {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 1em;
    margin-top: 0.2rem;
    align-items: center;
    border-radius: 1.2rem;
    background: #82a6d9;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    grid-gap: 0px;
    gap: 0px;
    justify-content: center;
    border: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .manage-license-upload-button:hover {
    background: #96b2d8;
  }
  .manage-license-file-name-section {
    color: var(--BigBlue-sys-light-secondary, #51606f);
    /* BigBlue/title/small */
    // font-family: "Space Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    align-self: flex-start;
    //styleName: BigBlue/title/small;
    font-family: Space Grotesk;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-top: -1.2em;
    margin-left: 0.3em;

    .manage-license-file-name {
      color: var(--BigBlue-sys-light-primary, #00639a);
      /* BigBlue/title/small */
      // font-family: "Space Grotesk";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      // font-family: Space Grotesk;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
  .manage-license-view-license-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    .manage-license-view-license-button {
      display: flex;
      align-self: flex-start;
      padding: 0.4em 2em;
      justify-content: center;
      align-items: center;
      color: var(--Big-Blue, #82a6d9);
      text-align: center;
      /* BigBlue/label/large */
      // font-family: "Space Grotesk";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      cursor: pointer;
      background-color: transparent;
      border-radius: 1.5em;
      border: 1px solid rgba(130, 166, 217, 1);
      width: Fixed (180px) px;
      height: Fixed (32px) px;
    }
  }
  .manage-license-sharing-section {
    display: flex;
    width: 720px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    .manage-license-sharing-title {
      color: var(--BigBlue-sys-light-secondary, #51606f);
      /* BigBlue/title/small */
      // font-family: "Space Grotesk";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .manage-license-option-1 {
      display: flex;
      padding: 4px 0px 4px 16px;
      align-items: center;
      align-self: stretch;
      border-radius: 4px 4px 0px 0px;
    }
    .manage-license-option-2 {
      display: flex;
      padding: 4px 0px 4px 16px;
      align-items: center;
      align-self: stretch;
      border-radius: 4px 4px 0px 0px;
    }
  }
  .manage-license-update-cancel-section {
    display: flex;
    width: 720px;
    height: 40px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1em;
    .manage-license-update-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 100px;
      background: rgba(0, 99, 154, 1);
      padding: 0.6em 1.5em;
      border: none;
      color: white;
    }
    .manage-license-update-btn:hover {
      background-color: #347ba1;
    }
    .manage-license-cancel-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 100px;
      background: transparent;
      padding: 0.6em 1.5em;
      border: none;
      // font-family: Space Grotesk;
      font-weight: 500;
      color: rgba(0, 99, 154, 1);
      border: none;
    }
  }
  .lightgrey-text-section {
    display: inline;
    color: rgba(187, 187, 187, 1);
  }
}
