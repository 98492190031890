.multi-select-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 16px;
  min-height: 52px;
  width: 720px;
  gap: 10px;
  border-radius: 8px;
  border: 1px dashed #51606f;
  background-color: #fcfcff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

.multi-input {
  border: none;
  height: 52px;
  background: transparent;
}

.suggested-list {
  padding: 5px 10px;
  position: absolute;
  margin-top: 10px;
  z-index: 99;
  border-radius: 8px;
  // width: 720px;
  background-color: #fcfcff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

.suggested-item {
  font-weight: 500;
  line-height: 16px;
  font-size: 12px;
  padding: 11px 16px;
}
.suggested-item:hover {
  cursor: pointer;
  background: #dde8f4;
  border-radius: 8px;
}
