.file-component {
    display: flex;
    height: 73px;
    margin: 32px 16px 0px 16px;
    border-bottom: 1px solid rgba(194, 199, 207, 1);

    .file-img {
        width: 56px;
        height: 56px;
    }

    .primary-file-name {
        width: 445px;
        height: 24px;
        margin: 16px;
        font-family: Space Mono;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(26, 28, 30, 1);
    }

    .download-file {
        width: 90px;
        height: 16px;
        margin: 28px 16px 28px 0;
        text-align: start;
        font-family: SpaceGrotesk;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(0, 99, 154, 1);

        &:hover {
            cursor: pointer;
        }
    }

    .replace-file {
        width: 43px;
        height: 16px;
        margin: 28px 0;
        font-family: SpaceGrotesk;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        text-align: right;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(66, 71, 78, 1);
    }

    .delete-file {
        width: 16px;
        height: 18px;
        margin: 22px 1px 22px 14px;
        color: rgba(81, 96, 111, 1);
        text-align: right;
    }
}