.authorization-popup {
    display: flex;
    flex-direction: column;
    width: 364px;
    height: 224px;
    border-radius: 28px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border: 1px solid rgba(255, 182, 72, 1);
    z-index: 1050;
    padding: 24px;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);

    .heading {
        width: 316px;
        height: 32px;
        gap: 0px;
        opacity: 0px;
        font-family: SpaceGrotesk;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(26, 28, 30, 1);
        margin-bottom: 16px;
    }

    .message {
        width: 316px;
        height: 40px;
        gap: 0px;
        opacity: 0px;
        font-family: Space Mono;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(66, 71, 78, 1);
    }

    .btn {
        width: 67px;
        height: 40px;
        padding: 10px 24px;
        gap: 8px;
        opacity: 0px;
        border-radius: 100px;
        background: rgba(0, 99, 154, 1);
        font-family: SpaceGrotesk;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(255, 255, 255, 1);
        align-self: flex-end;
        margin-top: 48px;

        &:hover {
            background-color: rgba(0, 99, 154, 1);
            color: rgba(255, 255, 255, 1);
        }
    }
}