.pill-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  // min-width: 125px;
  font-size: 11px;
  height: 28px;
  border-radius: 30px;
  padding: 4px 10px;
  word-break: break-all;
  gap: 10px;
  background: #dde8f4;
  margin-right: 5px;
  margin-bottom: 5px;
  & > img:hover {
    cursor: pointer;
  }
}
