.keywords-list {
    background: #FFFFFF;
    border-radius: 25px;
    padding: 17.5px 19px 7.5px;
    min-height: 100px;

    ul {
        display: flex;
        flex-wrap: wrap;

        li {
            background: #E8E8E8;
            border-radius: 15px;
            margin: 0 10px 10px;
            padding: 5px 15px;
            display: flex;
            align-items: center;
            min-height: 30px;
            text-transform: lowercase;
            word-break: break-word;

            &>img {
                margin-left: 10px;
                cursor: pointer;
            }

            &.add-new-keyword {
                cursor: pointer;

                img {
                    margin-left: 10px;
                    cursor: pointer;
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    .tags-input {
        border: none;
        font-family: 'Space Mono';
        margin: 0 10px 10px;
        height: 30px;
    }

    .input-toggler {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
}