.license-title {
  //styleName: BigBlue/body/small;
  font-family: Space Mono;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
 
.license-subtitle {
  //styleName: BigBlue/body/small;
  font-family: Space Mono;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.license-content {
  //styleName: BigBlue/body/small;
  font-family: Space Mono;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.license-container{
    padding: 2em;
    padding-top: 0em;
}
