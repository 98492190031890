.modal {
    &>.modal-dialog.add-sample-dataset-component {
        &>.modal-content {
            width: 840px;

            &>.modal-header {
                padding-left: 24px;

                &>.modal-title {
                    text-align: left;

                    .heading {
                        font-family: SpaceGrotesk;
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 28px;
                    }

                    .sub-heading {
                        font-family: Space Mono;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: left;
                        color: rgba(66, 71, 78, 1);

                    }
                }
            }

            &>.modal-body {
                padding-left: 8px;

                &>.data {
                    font-family: SpaceGrotesk;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    color: rgba(0, 29, 50, 1);
                }

                &>.upload-file-section {
                    height: 270px;
                    padding: 16px 10px 11px 10px;
                    border-radius: 8px;
                    border: 2px solid rgba(130, 166, 217, 1);
                    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);

                    &>.upload-file {
                        font-family: SpaceGrotesk;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        text-align: center;
                        padding: 95px 255px 0px 255px;
                    }

                    &>.upload-file-btn {
                        width: 120px;
                        margin-left: 330px;
                        border: 1px solid rgba(130, 166, 217, 1);
                        background-color: transparent;
                        border-radius: 30px;
                        font-family: SpaceGrotesk;
                        font-weight: 500;
                        text-align: center;
                        color: rgba(130, 166, 217, 1);
                    }
                }

                &>.create-package {
                    font-family: SpaceGrotesk;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    margin-top: 10px;
                }

                &>.package-name {
                    width: 800px;
                    height: 56px;
                    padding: 16px 10px 16px 10px;
                    gap: 10px;
                    border-radius: 8px;
                    border: 2px solid rgba(130, 166, 217, 1);
                    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);

                }
            }
            
            &>.modal-footer {
                justify-content: space-between;
                margin-top: 32px;


                &>.hg-button {
                    &>.btn.btn-secondary {
                        color: rgba(0, 99, 154, 1);
                    }
                }
            }
        }
    }
}