.published-datasets-component {
    &>.main {
        width: 100%;
        padding: 88px 130px 0 130px;

        &>.publishing-heading {
            font-size: 16px;
            color: rgba(130, 130, 130, 1);
        }

        &>.published-datasets {
            display: flex;
            margin-top: 36px;
            margin-bottom: 24px;
            font-family: Space Mono;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: rgba(114, 119, 127, 1);
        }
    }
}