.table-container {
  width: 100%;
}

.table-data {
  cursor: pointer;
  .action-btn {
    background-color: transparent;
    border: none;
  }
}

.action-btn:nth-child(1) {
  border-right: 2px solid black;
  margin: 0px 3px;
  padding: 0px 10px;
}

tr {
  border-bottom: 1px solid #e6e6e6;
}

tr:nth-child(even) {
  background-color: #f2f5f9;
}

td,
th {
  border: none;
  text-align: left;
  padding: 24px 12px;
}

td:nth-child(6),
th:nth-child(6) {
  text-align: right;
}

th {
  font-size: 14px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
