.card-component {
    height: auto;
    margin-bottom: 32px;
    padding: 20px 40px 20px 40px;
    gap: 10px;
    border-radius: 20px;
    background: rgba(250, 250, 250, 1);
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);

    &>.card-header {
        display: flex;
        justify-content: space-between;

        &>.card-title {
            font-family: spaceGrotesk;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: rgba(0, 0, 0, 1);
        }
    }

    &>.card-content {
        margin-top: 24px;

        &>.card-field {
            display: flex;
            padding-right: 77px;

            &>.label {
                min-width: 180px;
                height: 20px;
                font-family: spaceGrotesk;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                color: rgba(0, 0, 0, 1);
            }

            &>.value {
                height: auto;
                font-family: SpaceGrotesk;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: rgba(114, 119, 127, 1);

                &.with-space {
                    margin-right: 24px;
                }
            }

            &>.links {
                font-family: SpaceGrotesk;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: rgba(114, 119, 127, 1);
                text-decoration: underline;
            }

            &.seperation-line {
                border-bottom: 1px solid rgba(230, 230, 230, 1);
                margin-bottom: 10px;
            }
        }
    }
}