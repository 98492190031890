.modal {
  & > .modal-dialog.edit-basic-info-component {
    & > .modal-content {
      width: 882px;

      & > .modal-header {
        padding-left: 40px;

        & > .modal-title {
          font-family: SpaceGrotesk;
          font-size: 22px;
          font-weight: 400;
          line-height: 28px;
          text-align: left;
          color: rgba(26, 28, 30, 1);
        }
      }

      & > .modal-body {
        margin-top: 8px;
        padding-left: 24px;

        & > .headings {
          font-family: SpaceGrotesk;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: rgba(0, 0, 0, 1);
        }

        & > .custom-tags {
          &>.multi-select-container {
              width: 790px;
              min-height: 60px;
              gap: 20px;
              padding: 10px 10px 5px 10px;
              margin-top: 24px;
              margin-bottom: 27px;
              border-radius: 8px;
              box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
              box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
              background: rgba(252, 252, 255, 1);
              border-color: transparent;
              font-family: Space Mono;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              color: rgba(0, 0, 0, 1);
            }
        }

        & > .pill-list {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          padding: 8px;
          border: 1px solid #ccc;
          align-items: center;
          width: 790px;
          min-height: 60px;
          gap: 20px;
          padding: 16px 10px 11px 10px;
          margin-top: 24px;
          margin-bottom: 27px;
          border-radius: 8px;
          box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
          background: rgba(252, 252, 255, 1);
          border-color: transparent;
          font-family: Space Mono;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: rgba(0, 0, 0, 1);
        }

        & > .pill-list input {
          border: none;
          outline: none;
          flex-grow: 1;
          min-width: 100px;
          padding: 4px 8px;
        }

        & > .input-feilds {
          width: 790px;
          min-height: 60px;
          gap: 20px;
          padding: 16px 10px 11px 10px;
          margin-top: 24px;
          margin-bottom: 27px;
          border-radius: 8px;
          box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
          background: rgba(252, 252, 255, 1);
          border-color: transparent;
          font-family: Space Mono;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: rgba(0, 0, 0, 1);
        }

        & > .pricing {
          display: flex;
          margin-top: 24px;
          font-family: Space Mono;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: rgba(0, 0, 0, 1);

          & > .type {
            height: 44px;
            padding: 12px 21px 12px 0;
          }

          & > .paid {
            width: 100px;
            height: 44px;
            padding: 12px;
            margin-right: 40px;
            gap: 8px;
            border-radius: 8px;
            text-align: center;
            background: rgba(240, 240, 240, 1);
          }
        }

        & > .edit-pricing {
          width: 164px;
          height: 36px;
          padding: 8px 23px;
          margin: 24px 0;
          font-family: Space Mono;
          font-weight: 500;
          border: 0;
          border-radius: 30px;
          background: rgba(130, 166, 217, 1);
          text-align: left;
        }
        & > .edit-pricing:hover {
          background: rgba(130, 166, 217, 0.8);
        }
      }

      & > .modal-footer {
        justify-content: space-between;
        padding-left: 32px;
        padding-right: 40px;

        & > .hg-button {
          & > .btn.btn-secondary {
            width: 100px;
            border: 1px solid rgba(0, 99, 154, 1);
            color: rgba(0, 99, 154, 1);
            padding: 10px 27px;
            border-radius: 100px;
          }
        }
      }
    }
  }
}
