.ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ccc;

    & + .ql-container.ql-snow {
        border: none;
    }
}

.ql-editor {
    padding-left: 0;

    &.ql-blank {
        
        &::before {
            left: 0;
        }
    }
}