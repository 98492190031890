.search-bar {
  display: flex;
  justify-content: space-between;
  width: 424px;
  min-height: 40px;
  // margin-top: 30px;
  background-color: initial;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-family: "Space Mono";
  border-radius: 20px;
  border: 1px solid rgba(114, 119, 127, 1);
  padding: 8px;

  .pill-list {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
}

.search-bar input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 400;
  font-family: monospace;
  background-color: transparent;
}

.search-bar input::placeholder {
  font-family: Space Mono;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(114, 119, 127, 1);
}

.search-bar .search-icon {
  background: none;
  border: none;
  cursor: pointer;
  padding-right: 10px;
}
