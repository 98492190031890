@import "../App.scss";
@import "variables";

.modal {
  & > .modal-dialog.geospatial-variable-edit-component {
    & > .modal-content {
      // background: $big-blue-ref-neutral-neutral-96;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
        0px 1px 2px 0px rgba(0, 0, 0, 0.3);
      width: 160%;
      margin-left: -25%;

      & > .modal-header {
        border: none;

        & > .modal-title {
          color: #001d32;
          font-size: 24px;
          font-family: SpaceGrotesk;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0.1px;
          margin-bottom: -15px;
          margin-top: 12px;
          margin-left: 42px;
        }

        & > .btn-close {
        }
      }

      & > .modal-body {
        padding: 0;
        margin: 0 1rem;
        border-radius: 8px;
        width: 677px;
        // background: $big-blue-sys-light-on-primary;

        & > .content {
          width: 65%;
          height: 8%;
          margin-top: 31px;
          margin-left: 55px;

          & > .label-text-2 {
            color: #42474e;
            font-family: "Space Mono";
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }

          & > .label-text-3 {
            color: #51606f;
            font-family: "SpaceGrotesk";
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }

          & > .div-wrapper {
            & > .input-text-2 {
              color: #1a1c1e;
              font-family: "Space Mono";
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }

        & > .text-field-wrapper99 {
          display: flex;
          flex: 0 0 auto;
          flex-direction: column;
          position: relative;
          width: 632px;
          margin-top: 16px;
          margin-left: 62px;

          & > .state-layer-23 {
            align-items: center;
            align-self: stretch;
            border-radius: 4px 4px 0px 0px;
            display: flex;
            flex: 0 0 auto;
            padding: 4px 0px 4px 16px;
            position: relative;
            width: 100%;
            margin-top: 16px;

            & > .content11 {
              align-items: flex-start;
              display: flex;
              flex: 1;
              flex-direction: column;
              flex-grow: 1;
              height: 40px;
              justify-content: center;
              position: relative;

              & > .div-wrapper1 {
                align-items: center;
                display: inline-flex;
                flex: 0 0 auto;
                position: relative;

                & > .label-text-2 {
                  color: var(--bigbluesyslighton-surface-variant);
                  letter-spacing: var(--bigblue-body-small-letter-spacing);
                  margin-top: -1px;
                  position: relative;
                  white-space: nowrap;
                  width: fit-content;
                  color: #42474e;
                  font-family: "Space Mono";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                }

                & > .input-text-2 {
                  color: var(--bigbluesyslighton-surface);
                  font-family: var(--bigblue-body-large-font-family);
                  font-size: var(--bigblue-body-large-font-size);
                  font-style: var(--bigblue-body-large-font-style);
                  font-weight: var(--bigblue-body-large-font-weight);
                  letter-spacing: var(--bigblue-body-large-letter-spacing);
                  line-height: var(--bigblue-body-large-line-height);
                  margin-top: -1px;
                  position: relative;
                  white-space: nowrap;
                  width: fit-content;
                }
              }
            }
          }
        }

        & > .div-wrapper100 {
          margin-top: 35px;
          margin-left: 65px;

          & > .label-text-201 {
            color: #51606f;
            font-family: "SpaceGrotesk";
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
        }

        & > ul.versions-list {
          & > li {
            display: flex;
            padding: 4px 24px 4px 16px;
            align-items: center;
            gap: 16px;
            flex: 1 0 0;
            align-self: stretch;
            color: $big-blue-sys-light-on-surface;
            font-size: 14px;
            font-family: "Space Mono";
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            height: 40px;
            position: relative;
            cursor: pointer;

            &::after {
              content: "";
              background-image: url("../images/checkbox-blue.svg");
              background-size: 38px;
              width: 38px;
              height: 38px;
              position: absolute;
              right: 12px;
            }

            &.active {
              background: rgba(29, 27, 32, 0.08);

              &::after {
                background-image: url("../images/checkbox-blue-checked.svg");
              }
            }
          }
        }
      }

      & > .modal-footer {
        margin: 0;

        & > .togglebtn {
          display: flex;
          justify-content: space-between;
          width: 94%;
          margin-top: 65px;
          margin-bottom: 22px;

          & > .btnspecial {
            font-family: SpaceGrotesk;
            border-radius: 100px;
            background: #00639a;
            color: #fff;
            width: 84px;
            padding: 8px 12px;
            margin-right: 50px;
          }
          & > .btnspecial:hover {
            background: #347ba1;
          }
        }

        & > .hg-button {
          & > .btn {
            padding: 10px 24px 10px 24px;
            display: flex;
            height: 32px;
            padding: 6px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: $big-blue-sys-light-on-surface-variant;
            background-color: transparent;
            text-align: center;
            font-size: 14px;
            font-family: SpaceGrotesk;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            text-transform: capitalize;
            border: none;

            &.btn-secondary {
              background: transparent;
              width: 60px;
            }

            &.btn-primary {
              border-radius: 100px;
              background: $big-blue-sys-light-primary;
              color: #fff;
              padding: 20px 24px 20px 24px;
            }
          }
        }
      }
    }
  }
}
