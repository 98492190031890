@import '../App.scss';
@import 'variables';

.dsg-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 76px 10px;
    position: sticky;
    top: 0;
    z-index: 30;
    background: #FAFAFA;

    &>.user-profile-pic {
        position: relative;
        display: flex;
        align-items: center;

        & > img {
            width: 40px;
            height: 40px;
            margin-right: 16px;
            border-radius: 50%;
        }

        & > .profile-pic {
            display: flex;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            margin-right: 16px;
            color: $big-blue-sys-light-on-primary-container;
            font-size: 16px;
            font-family: SpaceGrotesk;
            font-weight: 500;
            line-height: 24px;
            border-radius: 50%;
            background: $big-blue-sys-light-primary-container;

        }

        & > .user-info {
            cursor: pointer;
            color: #505050;
            text-transform: uppercase;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            font-family: SpaceGrotesk;
            font-weight: 500;
            letter-spacing: 0.1px;
            display: flex;

            & > .logout {
                margin-left: 40px;
            }

            & > .user-email {
                font-size: 12px;
                color: #899DAB;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .dropdown-toggler {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
    
    .dropdown-list {
        background: $big-blue-ref-neutral-neutral-96;
        border-radius: 8px;
        position: absolute;
        top: 44px;
        width: 100%;
        padding: 16px;
        font-size: 14px;
        font-family: 'Space Mono';
        color: $big-blue-sys-light-on-surface;
        line-height: 20px;
        letter-spacing: 0.5px;
        z-index: 1;
        transition: all 250ms linear;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);

    
        ul {
            max-height: 215px;
            overflow-y: auto;
            background-color: $big-blue-sys-light-on-primary;
            padding: 0 24px 0 16px;
    
            li {
                padding: 10px 0;
                cursor: pointer;
                margin-right: 15px;
    
                &:first-child {
                    border-top: none;
                }
    
                &:last-child {
                    margin-bottom: 0;
                }
    
                &.logout {
                    border-top: none;
                    height: 34px;
                    background: #45B9FF;
                    border-radius: 9.02256px;
                    text-align: center;
                    margin: 6px 0 12px;
                    color: #001E30;
                }
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .hg-cms-header {
        padding: 200px 44px 25px;

        &>.logo {
            position: absolute;
            top: 50px;
        }

        &>.notifcation-area {
            &>.notifcation-bell {
                right: 135px;
                top: 82px;
            }
    
            &>.user-profile-pic {
                position: absolute;
                right: 52px;
                top: 73px;
            }
        }
    }
}