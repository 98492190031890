@import 'variables';

.access-info {
    padding: 0 16px;
    margin-top: 4px;

    &:not(:first-child) {
        // margin-top: 16px;
        // padding-top: 6px;
        // border-top: 1px solid #CAC4D0;;
    }
    

    &>.name-type {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        margin: -16px;
        padding: 16px;
        overflow: hidden;
        transition: all linear 200ms;

        &.open {
            background: $big-blue-state-layers-sys-light-on-surface-opacity-008;
            position: relative;

            &::after {
                content: '';
                background-image: url('../images/ripple.svg');
                background-size: 331.8px 43.875px;
                width: 331.8px;
                height: 43.875px;
                position: absolute;
                bottom: 0;
                right: -50px;
                z-index: 0;
            }
        }

        &>.user {
            display: flex;
            flex-direction: column;
            padding-left: 16px;
            min-width: 80%;
            max-width: 80%;

            &>.type {
                color: $m-3-sys-light-on-surface-variant;
                font-family: 'Space Mono';
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                /* 133.333% */
                letter-spacing: 0.5px;
            }

            &>.username {
                color: $big-blue-sys-light-on-surface;
                font-family: "Space Mono";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 150% */
                max-width: 80%;
            }
        }

        &>.collapse-control {
        position: absolute;
        right: 16px;
        top: 24px;
        cursor: pointer;
        z-index: 1;
        transition: all linear 200ms;

        &.open {
            rotate: 90deg;
        }
    }
    }



    

    &>.collapse, &>.collapsing {
        width: 100%;

        &>.access {
            padding: 32px 24px 20px;

            &>.edit-access,
            &>.toggle-access {
                color: $big-blue-sys-light-on-surface;
                font-family: SpaceGrotesk;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                /* 142.857% */
            }

            &>.toggle-access {
                color: $big-blue-sys-light-on-surface-variant;
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}