.list-dataset-container {
    display: grid;
    grid-template-columns:  25% 35% 30% 10%;
    height: 70px;
    border-bottom: 1px solid rgba(205, 212, 230, 1);
    margin-top: 30px;

    .dataset-details {
        display: flex;
        gap: 20px;

        .dataset-img {
            min-width: 40px;
            height: 40px;
            border-radius: 3px;
        }
    
        .dataset-info {
            min-height: 40px;
            gap: 4px;
            margin-right: 28px;
    
            .dataset-title {
                min-height: 20px;
                font-family: SpaceGrotesk;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                color: rgba(0, 0, 0, 1);
            }
    
            .organisation {
                height: 16px;
                font-family: monospace;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
                color: rgba(114, 119, 127, 1);
            }
        }
    }

    .dataset-description {
        max-width: 348px;
        height: 40px;
        font-family: monospace;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        margin-left: 28px;
        text-wrap: wrap;
        color: rgba(130, 130, 130, 1);
    }

    .data-type {
        max-width: 172px;
        height: 15px;
        font-family: monospace;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        margin-left: 26px;
        margin-right: 150px;
        color: rgba(130, 130, 130, 1);
    }

    .btn {
        width: 78px;
        height: 30px;
        gap: 10px;
        border-radius: 34px;
        //margin-left: 97px;
        margin-right: 27px;
        background: rgba(185, 200, 218, 1);
        font-family: monospace;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        color: rgba(14, 29, 42, 1);
    }
}