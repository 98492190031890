@import '../App.scss';
@import 'variables';

    .modal {

        & > .modal-dialog {
    
            & > .modal-content {
    
                & > .modal-body {
                    &>ul {
                        // max-height: 51vh;
                        // overflow-x: auto;
                        padding: 8px;

                        &>.col-header {
                                color: $big-blue-sys-light-on-surface;
                                font-size: 11px;
                                font-family: SpaceGrotesk;
                                font-weight: 500;
                                line-height: 16px;
                                text-transform: uppercase;
                                margin: 0 0 8px;
                        
                                &>.col-actions {
                                        cursor: pointer;
                                        display: flex;
                                        gap: 4px;
                                    
                                    &>span.active {
                                        color: $big-blue-sys-light-on-primary-fixed-variant;
                                    }
                                }
                            }

                        &>li {
                            display: flex;
                            justify-content: space-between;

                            &>.col-name {
                                overflow: hidden;
                                color: $big-blue-sys-light-on-surface;
                                text-overflow: ellipsis;
                                whitespace: nowrap;
                                font-size: 11px;
                                font-family: "Space Mono";
                                line-height: 20px;
                            }

                            &>.col-type {
                                color: $big-blue-sys-light-secondary;
                                text-align: right;
                                font-size: 11px;
                                font-family: "Space Mono";
                                line-height: 20px;
                                letter-spacing: 1.5px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
    
                & > .modal-footer {
                    border: none;
                }
            
            }
        }
    }

