.view-all-datasets {
    &>.header {
        width: 100%;
        height: 40px;
        margin-top: 32px;
    }

    &>.main {
        width: 100%;
        padding: 0 131px;

        &>.ocean-data {
            width: 288px;
            height: 24px;
            margin-top: 70px;
            font-family: "Segoe UI";
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: rgba(130, 130, 130, 1);
        }

        &>.all-datasets {
            display: flex;
            font-family: spaceGrotesk;
            font-size: 22px;
            font-weight: 400;
            line-height: 27px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: rgba(0, 0, 0, 1);
            margin-top: 10px;
        }

        & > .active_filter {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
          }

        .heading {
            margin-top: 40px;
            font-family: spaceGrotesk;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: rgba(69, 69, 69, 1);
        }

        .all-datasets-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin-top: 40px;
            column-gap: 24px;
            row-gap: 67px;
        }

        .pagination {
            margin: 40px 0px;
            display: flex;
            justify-content: center;
            position: relative;
        }
    }
}

