.ocean-of-data {
  & > .header {
    width: 100%;
    height: 40px;
    margin-top: 32px;
    & > .big-blue-cloud {
      padding-left: 94px;
    }
  }

  & > .main {
    width: 100%;
    padding-left: 128px;
    padding-right: 134px;
    & > .ocean-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 24px;
      margin-top: 70px;
      font-family: "Segoe UI";
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: rgba(130, 130, 130, 1);
    }

    & > .active_filter {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
    }

    & > .datasets {
      display: flex;
      justify-content: space-between;
      height: 20px;
      margin-top: 60px;
      gap: 10px;

      & > .heading {
        width: 400px;
        height: 20px;
        font-family: spaceGrotesk;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: rgba(69, 69, 69, 1);
      }

      & > .view-all {
        display: flex;
        width: 120px;
        height: 20px;
        left: 1008px;
        gap: 3px;

        .view-all-text {
          font-family: spaceGrotesk;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: right;
          color: rgba(194, 199, 207, 1);
        }

        .next-icon {
          width: 6.18px;
          height: 10px;
          top: 5px;
          left: 6.91px;
          margin-top: -4px;
          color: rgba(194, 199, 207, 1);
        }
      }
    }

    & > .popular-datasets-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      max-height: 210px;
      margin-top: 40px;
      column-gap: 24px;
      // row-gap: 88px;
      overflow-y: hidden;
    }

    & > .popular-datasets-list {
      display: grid;
      grid-template-columns: 1fr;
      //display: flex;
      max-height: 300px;
      margin-top: 40px;
      overflow-y: hidden;
    }

    & > .all-datasets-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      min-height: 161px;
      max-height: 930px;
      margin-top: 40px;
      column-gap: 24px;
      row-gap: 67px;
      overflow-y: hidden;
    }

    & > .all-datasets-list {
      display: grid;
      grid-template-columns: 1fr;
      //display: flex;
      max-height: 600px;
      margin-top: 40px;
      overflow-y: hidden;
    }

    & > .seperation-line {
      margin-top: 27px;
      margin-bottom: 38px;
      gap: 0px;
      border: 1px 0px 0px 0px;
      border: 1px solid rgba(205, 212, 230, 1);
    }
  }
}

.filter-btn {
  width: 130px;
  height: 40px;
  border-radius: 20px;
  background: transparent;
  margin-right: 8px;
  color: #72777f;
}

.filter-btn:hover {
  cursor: pointer;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  z-index: 100;
}

.filter-popup {
  position: relative;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 101;
  width: 560px;
}
