.modal {
    &>.modal-dialog.edit-access-modal {
        &>.modal-content {
            width: 360px;
            height: 576px;

            &>.modal-header {
                padding-left: 32px;

                &>.modal-title {
                    font-family: SpaceGrotesk;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 32px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: rgba(26, 28, 30, 1);
                }
            }

            &>.modal-body {
                &>.sub-heading {
                    height: 100px;
                    width: 296px;
                    font-family: Space Mono;
                    margin: 16px 46px 24px 20px;
                    text-wrap: wrap;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: rgba(66, 71, 78, 1);
                }

                &>.user-details {
                    height: 292px;
                    padding: 0px 16px 0px 16px;

                    &>.user-first-name,
                    &>.user-last-name,
                    &>.email-section,
                    &>.access-section {
                        margin: 8px 0px;
                        border-bottom: 2px solid #80808042;

                        &>.name {
                            height: 16px;
                            font-family: Roboto;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 16px;
                            letter-spacing: 0.5px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;

                        }

                        &>.user-info {
                            height: 24px;
                            font-family: Space Mono;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            margin-bottom: 9px;
                        }

                        &>.custom {
                            &:hover{
                                cursor: pointer;
                            }
                            &>.dropdown {
                                display: flex;
                                justify-content: space-between;

                            }
                        }
                    }

                    &>.dropdown-options {
                        width: 100px;
                        padding: 5px 10px;
                        position: absolute;
                        margin-top: -10px;
                        z-index: 99;
                        border-radius: 0px 0px 4px 4px;
                        background-color: #fcfcff;
                        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .3), 0 1px 3px 1px rgba(0, 0, 0, .15);

                        &>.dropdown-item{
                            &:hover {
                                background-color: rgba(216, 211, 211, 0.827);
                                color: rgba(66, 71, 78, 1);
                                cursor: pointer;
                              }
                        }
                    }
                }
            }
        }
    }
}